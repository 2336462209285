import React, { useState } from "react";
import { FormControl, FormHelperText } from "@mui/material";
import Select, { createFilter } from "react-select";
import WindowedSelect from "react-windowed-select";

var flatten = require("flat");

export const Dropdown = (props) => {
  const {
    errors,
    name,
    options,
    optionDisplayKey,
    optionValueKey,
    value,
    defaultValue,
    ...others
  } = props;

  const flatError = flatten(errors);

  const [dropValue, setDropValue] = useState(
    defaultValue ||
      (value
        ? {
            label: options.filter((obj) => obj[optionValueKey] == value)[0][
              optionDisplayKey
            ],
            vaule: value,
          }
        : null)
  );
  let formatOptions = [];
  options.map((ele) => {
    formatOptions.push({
      label: ele[optionDisplayKey],
      value: ele[optionValueKey],
    });
  });

  const handleOnchange = (val) => {
    const { setValue } = props;
    setDropValue(val);
    setValue(name, val.value);
  };

  return (
    <FormControl className="form--control">
      {/* <Select
        options={formatOptions}
        {...others}
        value={dropValue}
        onChange={handleOnchange}
        filterOption={null}
      /> */}
      <WindowedSelect
        options={formatOptions}
        {...others}
        menuPortalTarget={document.body}
        styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
        value={dropValue}
        onChange={handleOnchange}
        isDisabled={props.isDisabled}
      />
      {flatError[`${props.name}.message`] && (
        <FormHelperText error>
          {flatError[`${props.name}.message`]}
        </FormHelperText>
      )}
    </FormControl>
  );
};
