import React, { useContext, useEffect } from "react";
import { useNavigate } from "react-router";
import * as yup from "yup";

import { Form } from "../../components/ui/form/Form";
import { Input } from "../../components/ui/form/Input";
import { AuthContext } from "../../context/Auth.context";

import "./style.css";
import logo_white from "../../assets/logo_white.svg";

const defaultValues = {
  email: "",
  password: "",
};

const Login = () => {
  const contextData = useContext(AuthContext);
  const navigate = useNavigate();
  const { loginError } = contextData.state;
  const handleLogin = async (loginData) => {
    await contextData.login(loginData.email, loginData.password);
  };

  // A Temp fix to refresh need to add a permanent Solutin to this.
  useEffect(() => {
    const { access_token, user } = localStorage;

    if (access_token && user) {
      contextData.setLoginPending(false);
      contextData.setLoginSuccess(true);
      contextData.setUserData(JSON.parse(user));
      navigate("/dashboard");
    }
  }, []);

  return (
    <>
    {console.log(loginError)}
    <div className="wrapper login__wrapper">
      <div className="login_left">
        <img src={logo_white} alt="logo" />
      </div>
      <div className="login_right">
        <h1>Login</h1>
        { loginError?.status === 'success'
          && <p class="error">{loginError.message}</p>
        }
        <Form
          onSubmit={(loginData) => handleLogin(loginData)}
          defaultValues={defaultValues}
          validationSchema={{
            email: yup.string().required("Email is required"),
            password: yup.string().required("Password is required."),
          }}
          submitButtonText="Login"
          redirectUrl="/dashboard"
          apiError={contextData?.state?.loginError?.error}
        >
          <Input type="text" onBlur={() => contextData.setLoginError(null)} name="email" placeholder="Email" fullWidth />
          <Input
            type="password"
            name="password"
            placeholder="Password"
            fullWidth
          />
        </Form>
      </div>
    </div>
    </>
  );
};

export default Login;
