import React from "react";
import { CircularProgress } from "@mui/material";

import "./style.css";

export const Spinner = (props) => {
  return (
    <div className="spinner__wrapper">
      <CircularProgress {...props} />
    </div>
  );
};
