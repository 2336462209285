import Login from "../pages/Login";
import Dashboard from "../pages/Dashboard";
import Aboutus from "../pages/Aboutus";

import Student from "../pages/Student";
import AddStudent from "../pages/Student/Forms/AddStudent";
import EditStudent from "../pages/Student/Forms/EditStudent";
import StudentDetails from "../pages/Student/Details";

import Instructor from "../pages/Instructor";
import AddInstructor from "../pages/Instructor/Forms/AddInstructor";
import EditInstructor from "../pages/Instructor/Forms/EditInstructor";
import InstructorDetails from "../pages/Instructor/Details";


import Aircrafts from "../pages/Aircrafts";
import AddAircraft from "../pages/Aircrafts/Forms/";


import Scheduler from "../pages/Scheduler";
import CreateSchedule from "../pages/Scheduler/Forms/CreateScheule";

import AddInstitute from "../pages/Institute/AddInstitute";
import AddInstituteUser from "../pages/Institute/AddInstituteUser";

import PageNotFound from "../pages/PageNotFound";

const settings = {
  app_routes: [
    {
      path: "/login",
      element: <Login />,
      isProtected: false,
      isExact: true,
    },
    {
      path: "/dashboard",
      element: <Dashboard />,
      isProtected: false,
      isExact: true,
    },
    {
      path: "/students",
      element: <Student />,
      isProtected: false,
      isExact: true,
    },
    {
      path: "/students/:id/detail",
      element: <StudentDetails />,
      isProtected: false,
      isExact: true,
    },
    {
      path: "/students/add",
      element: <AddStudent />,
      isProtected: false,
      isExact: true,
    },
    {
      path: "/students/:id/edit",
      element: <EditStudent />,
      isProtected: false,
      isExact: true,
    },

    {
      path: "/instructors",
      element: <Instructor />,
      isProtected: false,
      isExact: true,
    },
    {
      path: "/instructors/:id/detail",
      element: <InstructorDetails />,
      isProtected: false,
      isExact: true,
    },
    {
      path: "/instructors/add",
      element: <AddInstructor />,
      isProtected: false,
      isExact: true,
    },
    {
      path: "/instructors/:id/edit",
      element: <EditInstructor />,
      isProtected: false,
      isExact: true,
    },
    

    {
      path: "/aircrafts",
      element: <Aircrafts />,
      isProtected: false,
      isExact: true,
    },
    {
      path: "/instructors/:id/detail",
      element: <InstructorDetails />,
      isProtected: false,
      isExact: true,
    },
    {
      path: "/aircrafts/add",
      element: <AddAircraft />,
      isProtected: false,
      isExact: true,
    },
    {
      path: "/aircrafts/:id/edit",
      element: <AddAircraft />,
      isProtected: false,
      isExact: true,
    },
    {
      path: "/add-institute",
      element: <AddInstitute />,
      isProtected: false,
      isExact: true,
    },
    {
      path: "/add-institute-user",
      element: <AddInstituteUser />,
      isProtected: false,
      isExact: true,
    },
    {
      path: "/schedules",
      element: <Scheduler />,
      isProtected: false,
      isExact: true,
    },
    {
      path: "/schedules/create/:id",
      element: <CreateSchedule />,
      isProtected: false,
      isExact: true,
    },    
    {
      path: "/about-us",
      element: <Aboutus />,
      isProtected: true,
      isExact: true,
    },
    {
      path: "*",
      element: <PageNotFound />,
      isProtected: true,
      isExact: true,
    },
  ],
  baseURL: "https://dev.api.mavaerosafety.com/",
};

export default settings;
