import React from "react";
import { Route, Routes } from "react-router-dom";

import settings from "./utils/settings";

const AppRoutes = () => (
  <Routes>
    {settings.app_routes.map(({ path, element, isExact, isProtected }) => {
      return <Route element={element} path={path} exact={isExact} />;
    })}
  </Routes>
);

export default AppRoutes;
