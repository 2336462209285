import React from "react";
import { useNavigate } from "react-router";

import withLayout from "../../hoc/Layout";
import { StudentProvider } from "../../context/Student.context";
import { StudentList } from "./Summary";
import { Button } from "../../components/ui/Button";

const Student = (props) => {
  const navigate = useNavigate();

  return (
    <div className="container-fluid">
      <div className="page-header">
        <h2>Students</h2>
        <Button variant="outlined" onClick={() => navigate("add")} size="small">
          Add Student
        </Button>
      </div>
      <div>
        <StudentProvider>
          <StudentList />
        </StudentProvider>
      </div>
    </div>
  );
};

export default withLayout(Student);
