import React, { useContext, useEffect, useMemo, useState } from "react";
import { useParams } from "react-router";

import * as yup from "yup";
import dayjs from "dayjs";
import { isEmpty } from "lodash";

import EventDispatcher from "../../../../../utils/EventDispatcher";
import { Button } from "../../../../../components/ui/Button";
import { Input } from "../../../../../components/ui/form/Input";
import { Date } from "../../../../../components/ui/form/DatePicker";
import { Form } from "../../../../../components/ui/form/Form";
import { Spinner } from "../../../../../components/ui/Spinner";
import { Modal } from "../../../../../components/ui/Modal";
import { Table } from "../../../../../components/ui/Table";
import { CommonContext } from "../../../../../context/Common.context";
import { Dropdown } from "../../../../../components/ui/form/Dropdown";
import { Box, IconButton } from "@mui/material";
import { Edit, Delete } from "@mui/icons-material";
import swal from "sweetalert";
import { options } from "../../../../../utils/SelectOptions";

const OtherRatingsForm = ({
  ratingChoice,
  countries,
  defaultValues,
  tableData,
  commonContextData,
}) => {
  const [initialEditValues, setInitialEditValues] = useState(defaultValues);
  const [openModal, setOpenModal] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  // const { rating_choice, countries } = options;
  const columns = useMemo(
    () => [
      {
        accessorKey: "rating_name",
        header: "Rating",
        size: 150,
      },
      {
        accessorKey: "rating_number",
        header: "Rating No.",
        size: 150,
      },
      {
        accessorKey: "initial_issue_date",
        header: "Initial Issuance Date",
        size: 150,
      },
      {
        accessorKey: "initial_valid_upto",
        header: "Valid Upto",
        size: 150,
      },
      {
        accessorKey: "renewal_date",
        header: "Renewal Date",
        size: 150,
      },
      {
        accessorKey: "renewal_valid_upto",
        header: "Valid Upto",
        size: 150,
      },
      {
        accessorKey: "issue_country",
        header: "Issuing Country",
        size: 150,
      },
    ],
    []
  );

  const validationSchema = {
    rating: yup.string().required("Rating is required"),
    rating_number: yup.string().required("Rating number is required"),
    initial_issue_date: yup
      .string()
      .required("Initial Issuance Date is required"),
    initial_valid_upto: yup
      .string()
      .required("Initial Valid Upto date is required"),
    renewal_date: yup.string().required("Renewal Date is required"),
    renewal_valid_upto: yup
      .string()
      .required("Renewal Valid Upto date is required"),
    issue_country: yup.string().required("Country is required"),
  };

  const submitForm = async (formData) => {
    const finalFormData = {
      ...formData,
      initial_issue_date: dayjs(formData.initial_issue_date).format(
        "YYYY-MM-DD"
      ),
      initial_valid_upto: dayjs(formData.initial_valid_upto).format(
        "YYYY-MM-DD"
      ),
      renewal_date: dayjs(formData.renewal_date).format("YYYY-MM-DD"),
      renewal_valid_upto: dayjs(formData.renewal_valid_upto).format(
        "YYYY-MM-DD"
      ),
    };
    console.log(finalFormData);
    if (isEdit) {
      finalFormData["user_id"] = formData.user;
    }
    await commonContextData.updateuserOtherRatingDetails(
      {
        ...finalFormData,
      },
      handleClose
    );
  };

  useEffect(() => {
    if (!isEdit) {
      setInitialEditValues(defaultValues);
    }
  }, [isEdit]);

  const deactivate = (id, endpoint, type) => {
    let msg = `Are you sure you want to delete the Other rating?`;
    swal(msg, "", "warning", {
      buttons: {
        cancel: true,
        confirm: true,
      },
    }).then((val) => {
      if (val) {
        let alertMsg = `Other rating deleted successfully.`;
        commonContextData.deleteAction(id, endpoint, type, async () => {
          await commonContextData.getInstrumentRating(id);

          swal(alertMsg, "", "success", {
            button: "ok",
          });
        });
      }
    });
  };

  const handleClose = () => {
    setOpenModal(false);
    setIsEdit(false);
  };
  const handleOpen = () => setOpenModal(true);

  return (
    <>
      <div className="sub-header">
        <h3>Other Ratings</h3>
        <Button onClick={handleOpen}>Add Other Ratings</Button>
        <Modal open={openModal} closeModal={handleClose}>
          <div className="grid2_2">
            <h4>Add Other Ratings</h4>
            <Form
              onSubmit={(formData) => submitForm(formData)}
              defaultValues={isEdit ? initialEditValues : defaultValues}
              validationSchema={validationSchema}
              submitButtonText="Submit"
              redirectUrl="/instructors"
            >
              <Dropdown
                fieldlabel="Rating"
                placeholder="Rating"
                name="rating"
                options={ratingChoice}
                optionDisplayKey="rating_name"
                optionValueKey="id"
                defaultValue={
                  isEdit
                    ? {
                        label: initialEditValues.rating_name,
                        value: initialEditValues.rating,
                      }
                    : null
                }
              />
              <Input
                type="text"
                name="rating_number"
                placeholder="Rating number"
                fullWidth
              />
              <Date label="Initial Issuance Date" name="initial_issue_date" />
              <Date label="Valid Upto" name="initial_valid_upto" />
              <Date label="Renewal Date" name="renewal_date" />
              <Date label="Valid Upto" name="renewal_valid_upto" />
              <Dropdown
                fieldlabel="Issue country"
                placeholder="Issue country"
                name="issue_country"
                options={countries}
                optionDisplayKey="country_name"
                optionValueKey="code"
                defaultValue={
                  isEdit
                    ? {
                        label: initialEditValues.country_name,
                        value: initialEditValues.issue_country,
                      }
                    : null
                }
              />
            </Form>
          </div>
        </Modal>
      </div>
      <div className="tableCard">
        <Table
          data={tableData}
          columns={columns}
          enableColumnActions={false}
          enableColumnFilters={false}
          enableTopToolbar={false}
          enableRowActions
          positionActionsColumn="last"
          renderRowActions={({ cell, row, table }) => (
            <Box sx={{ display: "flex", flexWrap: "nowrap", gap: "8px" }}>
              <IconButton
                color="primary"
                onClick={() => {
                  setInitialEditValues(cell.row.original);
                  setIsEdit(true);
                  setOpenModal(true);
                }}
              >
                <Edit />
              </IconButton>
              <IconButton
                color="primary"
                onClick={() => {
                  console.log(row);
                  deactivate(row.original.id, "ratings", "user-ratings");
                }}
              >
                <Delete />
              </IconButton>
            </Box>
          )}
        />
      </div>
    </>
  );
};

export const OtherRatings = () => {
  const { id } = useParams();
  const commonContextData = useContext(CommonContext);
  const { userOtherRatingDetails, choiceFields } = commonContextData.state;
  const [isLoaded, setLoaded] = useState(false);
  const { rating_choice, countries } = choiceFields;

  let initialValue = {
    user_id: id,
    rating_number: "",
    issue_country: "",
    rating: "",
  };

  useEffect(() => {
    if (!isLoaded) {
      (async () => {
        await commonContextData.getuserOtherRatingDetails(id);
        setLoaded(true);
      })();
    }
  }, [isLoaded]);

  return isLoaded ? (
    <OtherRatingsForm
      defaultValues={initialValue}
      tableData={userOtherRatingDetails}
      commonContextData={commonContextData}
      ratingChoice={rating_choice}
      countries={countries}
    />
  ) : (
    <Spinner />
  );
};
