import React, { useContext, useEffect, useMemo } from "react";
import { useNavigate } from "react-router-dom";

import { Block, Delete, Edit, Visibility } from "@mui/icons-material";
import { Box, IconButton } from "@mui/material";
import swal from "sweetalert";

import { AircraftContext } from "../../../context/Aircraft.context";
import { Table } from "../../../components/ui/Table";

export const AircraftList = () => {
  const aircraftContextData = useContext(AircraftContext);
  const navigate = useNavigate();
  const { aircraftList } = aircraftContextData.state;

  useEffect(() => {
    aircraftContextData.getAircraftList();
  }, []);

  const deactivate = (id, actiontype, status) => {
    let msg = `Are you sure you want to 
                ${actiontype === "deactivate" ? "deactivate" : "delete"} 
                this Aircraft?`;
    swal(msg, "", "warning", {
      buttons: {
        cancel: true,
        confirm: true,
      },
    }).then((val) => {
      if (val) {
        let alertMsg = `Aircraft ${
          actiontype === "deactivate" ? `${status ? "deactivated": "activated"}` : "deleted"
        } successfully.`;
        if (actiontype === "deactivate") {
          aircraftContextData.statusUpdate(id, status, 
          swal(alertMsg, "", "success", {
            button: "ok",
          }));
        } else {
          aircraftContextData.deleteAircraft(id,
          swal(alertMsg, "", "success", {
            button: "ok",
          }));
        }
      }
    });
  };
  //should be memoized or stable
  const columns = useMemo(
    () => [
      {
        accessorKey: "aircraft_type_name",
        header: "Aircraft Type",
      },
      {
        accessorKey: "registration_number",
        header: "Registration No.",
      },
      {
        accessorKey: "date_of_registration",
        header: "Date of registration",
      },
      {
        accessorKey: "status",
        header: "Status",
      },
    ],
    []
  );

  return (
    <div>
      <div className="tableCard">
        <Table
          data={aircraftList}
          columns={columns}
          initialState={{ columnVisibility: { id: false } }}
          enableRowActions
          enableBottomToolbar={false}
          enableTopToolbar={false}
          positionActionsColumn="last"
          renderRowActions={({ cell, row, table }) => (
            <Box sx={{ display: "flex", flexWrap: "nowrap", gap: "8px" }}>
              <IconButton
                color="primary"
                onClick={() => {
                  navigate(`${row.original.id}/edit`);
                }}
              >
                <Edit />
              </IconButton>
              <IconButton
                color="secondary"
                onClick={() => {
                  deactivate(row.original.id, "delete");
                }}
              >
                <Delete />
              </IconButton>
              <IconButton
                color="error"
                onClick={() => {
                  deactivate(row.original.id, "deactivate", true);
                }}
              >
                <Block />
              </IconButton>
            </Box>
          )}
        />
      </div>
    </div>
  );
};
