import React, { useContext, useEffect, useState } from "react";
import { useParams } from "react-router";

import { isEmpty } from "lodash";

import { CommonContext } from "../../../../context/Common.context";
import { License } from "./Licenses/License";
import { ELPLicense } from "./Licenses/ELPLicense";
import { AircraftEndorsement } from "./Licenses/AircraftEndorsement";
import { InstrumentRating } from "./Licenses/InstrumentRating";
import { Medical } from "./Licenses/Medical";
import { OtherRatings } from "./Licenses/OtherRatings";
import { Spinner } from "../../../../components/ui/Spinner";

export const Licenses = (props) => {
  const commonContextData = useContext(CommonContext);
  const { choiceFields } = commonContextData.state;
  const [isLoaded, setLoaded] = useState(false);

  useEffect(() => {
    if (!isLoaded) {
      (async () => {               
        if(isEmpty(choiceFields)) {
          await commonContextData.getChoiceFields();
        }
        setLoaded(true);        
      })();
    }
  }, [isLoaded]);
  return isLoaded ? (
    <>
      <License />
      <br/>
      <ELPLicense />
      <br/>
      <AircraftEndorsement />
      <br/>
      <InstrumentRating />
      <br/>
      <Medical />
      <br/>
      <OtherRatings />
    </>
  ): (
    <Spinner />
  );
};
