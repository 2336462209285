import React, { useContext, useEffect, useMemo, useState } from "react";
import { useParams } from "react-router";

import * as yup from "yup";
import { isEmpty } from "lodash";

import { Button } from "../../../../components/ui/Button";
import { Input } from "../../../../components/ui/form/Input";
import { DynamicForm } from "../../../../components/ui/form/DynamicForm";
import { Form } from "../../../../components/ui/form/Form";
import { Spinner } from "../../../../components/ui/Spinner";
import { Modal } from "../../../../components/ui/Modal";
import { Table } from "../../../../components/ui/Table";
import { CommonContext } from "../../../../context/Common.context";
import { Dropdown } from "../../../../components/ui/form/Dropdown";
import { Box, IconButton } from "@mui/material";
import { Edit, Delete } from "@mui/icons-material";
import swal from "sweetalert";

const ExperienceForm = ({
  aircraftType,
  defaultValues,
  tableData,
  commonContextData,
}) => {
  const [initialEditValues, setInitialEditValues] = useState(defaultValues);
  const [openModal, setOpenModal] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const columns = useMemo(
    () => [
      {
        accessorKey: "flight_school",
        header: "Flight School",
      },
      {
        accessorKey: "aircraft_type_name",
        header: "Aircraft Type",
      },
      {
        accessorKey: "fight_type_name",
        header: "Flight Type",
      },
    ],
    []
  );
  const timeValidate = /^([0-1]?[0-9]|2[0-4]):([0-5][0-9]):([0-5][0-9])?$/;
  const validationSchema = {
    aircraft_type: yup.string().required("aircraft_type is required"),
    instrument_time_actual_hours: yup
      .number()
      .typeError("instrument time actual hours must be a number")
      .nullable()
      .moreThan(0, "instrument time actual hours cannot be 0 or less than 0")
      .transform((_, val) => (val !== "" ? Number(val) : null)),
    instrument_time_simulated_hours: yup
      .number()
      .typeError("instrument time simulated hours must be a number")
      .nullable()
      .moreThan(0, "instrument time simulated hours cannot be 0 or less than 0")
      .transform((_, val) => (val !== "" ? Number(val) : null)),
    instructional: yup
      .number()
      .typeError("instructional time must be a number")
      .nullable()
      .moreThan(0, "instructional time be 0 or less than 0")
      .transform((_, val) => (val !== "" ? Number(val) : null)),
    flightexperiencedata_set: yup.array(
      yup.object({
        time_choice: yup.string().required("time_choice is required"),
        pilot_role: yup.string().required("pilot_role is required"),
        total_hours: yup
          .number()
          .typeError("Total hours must be a number")
          .nullable()
          .moreThan(0, "Total hours cannot be 0 or less than 0")
          .transform((_, val) => (val !== "" ? Number(val) : null)),
      })
    ),
  };

  const deactivate = (id, type, endpoint) => {
    let msg = `Are you sure you want to delete the experience?`;
    swal(msg, "", "warning", {
      buttons: {
        cancel: true,
        confirm: true,
      },
    }).then((val) => {
      if (val) {
        let alertMsg = `experience deleted successfully.`;
        commonContextData.deleteAction(id, type, endpoint, async () => {
          await commonContextData.getUserExperienceProfile(id);

          swal(alertMsg, "", "success", {
            button: "ok",
          });
        });
      }
    });
  };

  const submitForm = async (formData) => {
    console.log(formData);
    if (isEdit) {
      formData["user_id"] = formData.user;
    }
    await commonContextData.updateUserExperienceProfile(
      {
        ...formData,
      },
      handleClose
    );
  };

  useEffect(() => {
    console.log(initialEditValues);
    if (!isEdit) {
      setInitialEditValues(defaultValues);
    }
  }, [isEdit]);

  const handleClose = () => {
    setOpenModal(false);
    setIsEdit(false);
  };
  const handleOpen = () => setOpenModal(true);

  return (
    <>
      <div className="sub-header">
        <h3>Experience</h3>
        <Button onClick={handleOpen}>Add Experience</Button>
        <Modal open={openModal} closeModal={handleClose}>
          <div className="grid2_2">
            <Form
              onSubmit={(formData) => submitForm(formData)}
              defaultValues={isEdit ? initialEditValues : defaultValues}
              validationSchema={validationSchema}
              submitButtonText="Submit"
              redirectUrl="/instructors"
              className="form-experience"
            >
              <Dropdown
                fieldlabel="Aircraft Type"
                placeholder="Aircraft Type"
                name="aircraft_type"
                options={aircraftType}
                optionDisplayKey="aircraft_type"
                optionValueKey="id"
                defaultValue={
                  isEdit
                    ? {
                        label: initialEditValues.aircraft_type_name,
                        value: initialEditValues.aircraft_type,
                      }
                    : null
                }
              />
              <Input
                type="text"
                name="flight_type"
                placeholder="Flight type"
                fullWidth
              />
              <Input
                type="number"
                name="instrument_time_actual_hours"
                placeholder="Instrument time actual hours"
                fullWidth
              />
              <Input
                type="number"
                name="instrument_time_simulated_hours"
                placeholder="Instrument time simulated hours"
                fullWidth
              />
              <Input
                type="number"
                name="instructional"
                placeholder="instructional"
                fullWidth
              />
              <div class="subheader">
                <h2>Flight Experience data</h2>
              </div>
              <DynamicForm
                name="flightexperiencedata_set"
                addLabel="Add Flight Experience"
                isDynamicForm
              >
                {/* <Input
                type="text"
                name="flight_experience_id"
                placeholder="flight_experience_id"
                fullWidth
                style={{ display: "none" }}
              /> */}
                <Input
                  type="text"
                  name="time_choice"
                  placeholder="Time choice"
                  fullWidth
                />
                <Input
                  type="text"
                  name="pilot_role"
                  placeholder="Pilot role"
                  fullWidth
                />
                <Input
                  type="number"
                  name="total_hours"
                  placeholder="Total hours"
                  fullWidth
                />
              </DynamicForm>
            </Form>
          </div>
        </Modal>
      </div>
      <div className="tableCard">
        <Table
          data={tableData}
          columns={columns}
          enableColumnActions={false}
          enableColumnFilters={false}
          enableTopToolbar={false}
          enableRowActions
          positionActionsColumn="last"
          renderRowActions={({ cell, row, table }) => (
            <Box sx={{ display: "flex", flexWrap: "nowrap", gap: "8px" }}>
              <IconButton
                color="primary"
                onClick={() => {
                  setInitialEditValues(cell.row.original);
                  setIsEdit(true);
                  setOpenModal(true);
                  // EventDispatcher.dispatch("openModal", {
                  //   setEditMode: setIsEdit,
                  //   isEdit: true,
                  // });
                }}
              >
                <Edit />
              </IconButton>
              <IconButton
                color="primary"
                onClick={() => {
                  deactivate(
                    row.original.experience_id,
                    "experience",
                    "user-experience"
                  );
                }}
              >
                <Delete />
              </IconButton>
            </Box>
          )}
        />
      </div>
    </>
  );
};

export const Experience = () => {
  const { id } = useParams();
  const commonContextData = useContext(CommonContext);
  const { userExperience, choiceFields } = commonContextData.state;
  const [isLoaded, setLoaded] = useState(false);
  const { aircraft_type } = choiceFields;

  let initialValue = {
    user_id: id,
    // experience_id: null,
    flightexperiencedata_set: [
      {
        // flight_experience_id: null,
        time_choice: null,
        pilot_role: null,
        total_hours: null,
      },
    ],
  };

  useEffect(() => {
    if (!isLoaded) {
      (async () => {
        await commonContextData.getUserExperienceProfile(id);
        await commonContextData.getChoiceFields();
        setLoaded(true);
      })();
    }
  }, [isLoaded]);

  return isLoaded ? (
    <ExperienceForm
      defaultValues={initialValue}
      tableData={userExperience}
      commonContextData={commonContextData}
      aircraftType={aircraft_type}
    />
  ) : (
    <Spinner />
  );
};
