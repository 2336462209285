import React, { useState } from "react";
import swal from "sweetalert";

import api from "../utils/axios";
import { useNavigate } from "react-router";

export const AircraftContext = React.createContext(null);

const initialState = {
  aircraftList: [],
  aircraftCheck: [],
  aircraftCategory: [],
  editAircraftData: {},
};

export const AircraftProvider = (props) => {
  const navigate = useNavigate();
  const [state, setState] = useState(initialState);
  const setAircraftList = (aircraftList) => {
    setState((prevState) => ({ ...prevState, aircraftList }));
  };
  const setAircraftCheck = (aircraftCheck) => {
    setState((prevState) => ({ ...prevState, aircraftCheck }));
  };
  const setAircraftDataedit = (editAircraftData) => {
    setState((prevState) => ({ ...prevState, editAircraftData }));
  };
  const setAircraftCategory = (aircraftCategory) => {
    setState((prevState) => ({ ...prevState, aircraftCategory }));
  }

  /** Get all students */
  const getAircraftList = async () => {
    try {
      const aircraftList = await api.get(`aircraft/view-aircraft-list`);

      setAircraftList(aircraftList?.data[0].data || []);
      return aircraftList;
    } catch (error) {
      console.log("Error", error);
    }
  };

  /** Get check */
  const getAircraftCheck = async () => {
    try {
      const aircraftCheck = await api.get(`aircraft/aircraft-check`);

      setAircraftCheck(aircraftCheck?.data[0].data || []);
    } catch (error) {
      console.log("Error", error);
    }
  };

  /** Get Aircraft category */
  const getAircraftCategory = async () => {
    try {
      const aircraftCategory = await api.get(`aircraft/aircraft-category`);

      setAircraftCategory(aircraftCategory?.data[0].data || []);
    } catch (error) {
      console.log("Error", error);
    }
  };
  /** Add user (student/instructor) */
  const addAircraft = async (data, redirectURL) => {
    try {
      await api.post(`aircraft/add-aircraft/`, data);
      swal("New Aircraft Added Successfully", "", "success", {
        button: "ok",
      }).then(() => {
        navigate(redirectURL);
      });
    } catch (error) {
      swal("Error in Adding Aircarft", "", "danger", {
        button: "ok",
      });
    }
  };
  const setEditAricraftData = (data) => {
    setAircraftDataedit(data);
  };

  const statusUpdate = async (id, status, callback) => {
    try {
      await api.put(`aircraft/${id}/aircraft-status-change/`, {
        deactivate: status,
      });
      callback();
    } catch (error) {
      console.log("Error", error);
    }
  };
  const deleteAircraft = async (id, callback) => {
    try {
      await api.post(`aircraft/${id}/delete-aircraft/`);
      callback();
    } catch (error) {
      console.log("Error", error);
    }
  };

  return (
    <AircraftContext.Provider
      value={{
        state,
        getAircraftList,
        getAircraftCheck,
        getAircraftCategory,
        addAircraft,
        setEditAricraftData,
        deleteAircraft,
        statusUpdate,
      }}
    >
      {props.children}
    </AircraftContext.Provider>
  );
};
