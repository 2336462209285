import React, { useContext, useEffect, useState } from "react";
import { useParams } from "react-router";

import { Input } from "../../../../components/ui/form/Input";
import { Form } from "../../../../components/ui/form/Form";
import { CommonContext } from "../../../../context/Common.context";
import { Spinner } from "../../../../components/ui/Spinner";
import { isEmpty } from "lodash";

const EmergencyForm = ({ defaultValues, commonContextData }) => {
  const submitForm = async (formData) => {
    await commonContextData.updateUserEmergencyProfile(formData);
  };

  return (
    <Form
      onSubmit={(formData) => submitForm(formData)}
      defaultValues={{ ...defaultValues }}
      submitButtonText="Submit"
      redirectUrl="/instructors"
    >
      <Input type="text" name="full_name" placeholder="Full Name" fullWidth />
      <Input type="text" name="relation" placeholder="Relation" fullWidth />
      <Input type="text" name="contact" placeholder="Contact" fullWidth />
    </Form>
  );
};

export const Emergency = () => {
  const { id } = useParams();
  const commonContextData = useContext(CommonContext);
  const { userEmergency } = commonContextData.state;
  const [isLoaded, setLoaded] = useState(false);
  let initialValue = { ...userEmergency, user_id: id };

  /** if emergency profile already exist then insert emergency_id into payload */
  if (!isEmpty(userEmergency)) {
    initialValue = {
      ...initialValue,
      emergency_id: userEmergency.id,
    };
  }

  useEffect(() => {
    if (!isLoaded) {
      (async () => {
        await commonContextData.getUserEmergencyProfile(id);
        setLoaded(true);
      })();
    }
  }, [isLoaded]);

  return isLoaded ? (
    <EmergencyForm
      defaultValues={initialValue}
      commonContextData={commonContextData}
    />
  ) : (
    <Spinner />
  );
};
