import React, { useState } from "react";
import swal from "sweetalert";

import api from "../utils/axios";
import { useNavigate } from "react-router";

export const InstructorContext = React.createContext(null);

const initialState = {
  instructorList: [],
};

export const InstructorProvider = (props) => {
  const navigate = useNavigate();
  const [state, setState] = useState(initialState);
  const setInstructorData = (instructorList) => {
    setState((prevState) => ({ ...prevState, instructorList }));
  };

  /** Get all instructors */
  const getInstructors = async () => {
    try {
      const getInstructorData = await api.get(
        `accounts/account-user-invite/instructor-list/`
      );

      setInstructorData(getInstructorData?.data[0].data || []);
    } catch (error) {
      console.log("Error", error);
    }
  };

  /** Add user (instructor/instructor) */
  const addUser = async (data, redirectURL) => {
    try {
      await api.post(`accounts/account-user-invite/invite-instructor/`, data);
      swal("New Instructor Added Successfully", "", "success", {
        button: "ok",
      }).then(() => {
        navigate(redirectURL);
      });
    } catch (error) {
      swal("Error in Adding instructor", "", "danger", {
        button: "ok",
      });
    }
  };

  return (
    <InstructorContext.Provider
      value={{
        state,
        getInstructors,
        addUser,
      }}
    >
      {props.children}
    </InstructorContext.Provider>
  );
};
