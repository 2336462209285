import React, { useContext, useEffect, useMemo, useState } from "react";
import { useParams } from "react-router";

import * as yup from "yup";
import dayjs from "dayjs";
import { isEmpty } from "lodash";

import EventDispatcher from "../../../../../utils/EventDispatcher";
import { Button } from "../../../../../components/ui/Button";
import { Input } from "../../../../../components/ui/form/Input";
import { Date } from "../../../../../components/ui/form/DatePicker";
import { Form } from "../../../../../components/ui/form/Form";
import { Spinner } from "../../../../../components/ui/Spinner";
import { Modal } from "../../../../../components/ui/Modal";
import { Table } from "../../../../../components/ui/Table";
import { CommonContext } from "../../../../../context/Common.context";
import { Dropdown } from "../../../../../components/ui/form/Dropdown";
import { Box, IconButton } from "@mui/material";
import { Edit, Delete } from "@mui/icons-material";
import swal from "sweetalert";
import { options } from "../../../../../utils/SelectOptions";

const MedicalForm = ({
  medicalExaminationChoices,
  medicalStatusChoices,
  bloodGroupChoices,
  defaultValues,
  tableData,
  commonContextData,
}) => {
  const [initialEditValues, setInitialEditValues] = useState(defaultValues);
  const [openModal, setOpenModal] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  // const { medical_examination, medical_status, blood_group } = options;
  const columns = useMemo(
    () => [
      {
        accessorKey: "medical_examination",
        header: "Class of Medical Examination",
      },
      {
        accessorKey: "medical_status",
        header: "Medical Status",
      },
      {
        accessorKey: "date_of_exam",
        header: "Date of Examination",
      },
      {
        accessorKey: "valid_upto",
        header: "Valid Upto",
      },
      {
        accessorKey: "blood_group",
        header: "Blood Group",
      },
    ],
    []
  );

  const validationSchema = {
    medical_examination: yup
      .string()
      .required("Class of medical examination is required"),
    medical_status: yup.string().required("Medical status is required"),
    date_of_exam: yup.string().required("Date of Examination is required"),
    valid_upto: yup.string().required("Valid Upto date is required"),
    blood_group: yup.string().required("Blood group is required"),
  };

  const submitForm = async (formData) => {
    const { date_of_exam, valid_upto, user } = formData;

    const finalFormData = {
      ...formData,
      date_of_exam: dayjs(date_of_exam).format("YYYY-MM-DD"),
      valid_upto: dayjs(valid_upto).format("YYYY-MM-DD"),
    };
    if (isEdit) {
      finalFormData["user_id"] = user;
    }

    await commonContextData.updateuserMedicalDetails(
      {
        ...finalFormData,
      },
      handleClose
    );
  };

  useEffect(() => {
    if (!isEdit) {
      setInitialEditValues(defaultValues);
    }
  }, [isEdit]);

  const deactivate = (id, endpoint, type) => {
    let msg = `Are you sure you want to delete the Medical?`;
    swal(msg, "", "warning", {
      buttons: {
        cancel: true,
        confirm: true,
      },
    }).then((val) => {
      if (val) {
        let alertMsg = `Medical deleted successfully.`;
        commonContextData.deleteAction(id, endpoint, type, async () => {
          await commonContextData.getuserMedicalDetails(id);

          swal(alertMsg, "", "success", {
            button: "ok",
          });
        });
      }
    });
  };

  const handleClose = () => {
    setOpenModal(false);
    setIsEdit(false);
  };
  const handleOpen = () => setOpenModal(true);

  return (
    <>
      <div className="sub-header">
        <h3>Medical Details</h3>
        <Button onClick={handleOpen}>Add Medical Details</Button>
        <Modal open={openModal} closeModal={handleClose}>
          <div className="grid2_2">
            <h4>Add Medical Details</h4>
            <Form
              onSubmit={(formData) => submitForm(formData)}
              defaultValues={isEdit ? initialEditValues : defaultValues}
              validationSchema={validationSchema}
              submitButtonText="Submit"
              redirectUrl="/instructors"
            >
              <Dropdown
                fieldlabel="Medical examination"
                placeholder="Medical examination"
                name="medical_examination"
                options={medicalExaminationChoices}
                optionDisplayKey="option"
                optionValueKey="value"
                defaultValue={
                  isEdit
                    ? {
                        label: initialEditValues.medical_examination_name,
                        value: initialEditValues.medical_examination,
                      }
                    : null
                }
              />
              <Dropdown
                fieldlabel="Medical status"
                placeholder="Medical status"
                name="medical_status"
                options={medicalStatusChoices}
                optionDisplayKey="option"
                optionValueKey="fit"
                defaultValue={
                  isEdit
                    ? {
                        label: initialEditValues.medical_status_name,
                        value: initialEditValues.medical_status,
                      }
                    : null
                }
              />
              <Date label="Date of Examination" name="date_of_exam" />
              <Date label="Valid Upto" name="valid_upto" />
              <Dropdown
                fieldlabel="Blood group"
                placeholder="Blood group"
                name="blood_group"
                options={bloodGroupChoices}
                optionDisplayKey="group_name"
                optionValueKey="id"
                defaultValue={
                  isEdit
                    ? {
                        label: initialEditValues.blood_group_name,
                        value: initialEditValues.blood_group,
                      }
                    : null
                }
              />
            </Form>
          </div>
        </Modal>
      </div>
      <div className="tableCard">
        <Table
          data={tableData}
          columns={columns}
          enableColumnActions={false}
          enableColumnFilters={false}
          enableTopToolbar={false}
          enableRowActions
          positionActionsColumn="last"
          renderRowActions={({ cell, row, table }) => (
            <Box sx={{ display: "flex", flexWrap: "nowrap", gap: "8px" }}>
              <IconButton
                color="primary"
                onClick={() => {
                  setInitialEditValues(cell.row.original);
                  setIsEdit(true);
                  setOpenModal(true);
                }}
              >
                <Edit />
              </IconButton>
              <IconButton
                color="primary"
                onClick={() => {
                  console.log(row);
                  deactivate(row.original.id, "medical", "user-medical");
                }}
              >
                <Delete />
              </IconButton>
            </Box>
          )}
        />
      </div>
    </>
  );
};

export const Medical = () => {
  const { id } = useParams();
  const commonContextData = useContext(CommonContext);
  const { userMedicalDetails, choiceFields } = commonContextData.state;
  const [isLoaded, setLoaded] = useState(false);
  const { medical_examination, medical_status, blood_group } = choiceFields;

  let initialValue = {
    user_id: id,
    medical_examination: "",
    medical_status: "",
    blood_group: null,
  };

  useEffect(() => {
    console.log();
    if (!isLoaded) {
      (async () => {
        await commonContextData.getuserMedicalDetails(id);
        setLoaded(true);
      })();
    }
  }, [isLoaded]);

  return isLoaded ? (
    <MedicalForm
      defaultValues={initialValue}
      tableData={userMedicalDetails}
      commonContextData={commonContextData}
      medicalExaminationChoices={medical_examination}
      medicalStatusChoices={medical_status}
      bloodGroupChoices={blood_group}
    />
  ) : (
    <Spinner />
  );
};
