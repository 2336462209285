import React, { useContext, useEffect, useState } from "react";
import { useParams } from "react-router";
import * as yup from "yup";
import swal from "sweetalert";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";

import withLayout from "../../../../hoc/Layout";
import { ScheduleContext, ScheduleProvider } from "../../../../context/Scheduler.context";
import { Input } from "../../../../components/ui/form/Input";
import { DateTimePicker } from "../../../../components/ui/form/DateTimePicker";
import { Form } from "../../../../components/ui/form/Form";
import { Dropdown } from "../../../../components/ui/form/Dropdown";
import { CommonContext } from "../../../../context/Common.context";
import { options } from "../../../../utils/SelectOptions";

import { Spinner } from "../../../../components/ui/Spinner";

dayjs.extend(utc);

const CreateScheduleForm = () => {
  const { id } = useParams();
  const scheduleContextData = useContext(ScheduleContext);
  const commonContextData = useContext(CommonContext);
  const { studentList, instructorList, aircraftList } = scheduleContextData.state;
  const { aircraft_type } = commonContextData.state.choiceFields;
  const { pilot_type, flight_type } = options;
  const [isLoaded, setLoaded] = useState(false);

  // start_time: "2023-06-03T17:30:56Z",
  // end_time: "2023-06-03T17:30:56Z",
  const defaultValues = {
    student_id: "",
    instructor_id: "",
    aircraft_registration_id: id,
    pilot_function: "",
    type_of_flight: ""
  };

  const validationSchema = {
    student_id: yup.string().required("You must select an option!"),
    instructor_id: yup.string().required("You must select an option!"),
    aircraft_registration_id: yup.string().required("You must select an option!"),
    start_time: yup.string().required("Start time is required."),
    end_time: yup.string().required("End time is required."),
    pilot_function: yup.string().required("You must select an option!"),
    type_of_flight: yup.string().required("You must select an option!"),
  };

  const addStudent = async (formData) => {
    const {start_time, end_time} = formData;

    const finalFormData = {
      ...formData,
      start_time: dayjs.utc(start_time).format(),
      end_time: dayjs.utc(end_time).format(),
    };
    
    await scheduleContextData.addSchedule(finalFormData, "/schedules");
  };

  useEffect(() => {
    if (!isLoaded) {
      (async () => {
        await commonContextData.getChoiceFields();
        await scheduleContextData.getStudentList();
        await scheduleContextData.getInstructorList();
        await scheduleContextData.getAircraftList();
        setLoaded(true);
      })();
    }
  }, [isLoaded]);

  return isLoaded ? (
    <>
    {console.log(studentList, instructorList)}
      <Form
      onSubmit={(studentData) => addStudent(studentData)}
      defaultValues={defaultValues}
      submitButtonText="Submit"
      validationSchema={validationSchema}
      redirectUrl="/schedules"
      >
      <Dropdown
        fieldlabel="Student List"
        placeholder="Student List"
        name="student_id"
        options={studentList}
        optionDisplayKey="first_name"
        optionValueKey="id"
      /> 
      
      <Dropdown
        fieldlabel="Instructor List"
        placeholder="Instructor List"
        name="instructor_id"
        options={instructorList}
        optionDisplayKey="first_name"
        optionValueKey="id"
      /> 
      <DateTimePicker label="Start Date" name="start_time" />
      <DateTimePicker label="End Date" name="end_time" />
      
      <Dropdown
        fieldlabel="Aircraft type"
        placeholder="Aircraft type"
        name="aircraft_registration_id"
        options={aircraftList}
        optionDisplayKey="aircraft_type_name"
        optionValueKey="id"
        isDisabled={true}
      />
      <Dropdown
        fieldlabel="Pilot Type"
        placeholder="Pilot Type"
        name="pilot_function"
        options={pilot_type}
        optionDisplayKey="label"
        optionValueKey="id"
      />
      <Dropdown
        fieldlabel="Flight Type"
        placeholder="Flight Type"
        name="type_of_flight"
        options={flight_type}
        optionDisplayKey="label"
        optionValueKey="id"
      />
      </Form>
    </>
  ): (
    <Spinner />
  );

}
const CreateSchedule = () => {
  return (
    <div className="container-fluid">
      <div className="page-header">
        <h2>Create Schedule</h2>
      </div>
      <div className="page-content grid2_2">
      <ScheduleProvider>
        <CreateScheduleForm />
      </ScheduleProvider>
      </div>
    </div>
  );
};

export default withLayout(CreateSchedule);
