import React from "react";
import { Sidebar, Menu, MenuItem, useProSidebar } from 'react-pro-sidebar';
import { NavLink } from 'react-router-dom'
import { Dashboard, KeyboardArrowLeft } from "@mui/icons-material";
import SpatialAudioOffIcon from '@mui/icons-material/SpatialAudioOff';
import AirplanemodeActiveIcon from '@mui/icons-material/AirplanemodeActive';
import EditCalendarIcon from '@mui/icons-material/EditCalendar';
import { School } from "@mui/icons-material";


import MenuRoundedIcon from '@mui/icons-material/MenuRounded';

export const Sidenav = React.memo(props => {
  const { collapseSidebar, toggled, toggleSidebar } = useProSidebar();

  return (
    <div className="sidebar__wrapper">
      <Sidebar defaultCollapsed={true}>
        <Menu>
          <NavLink to="/dashboard" >
            <MenuItem icon={<Dashboard />}>
              Dashboard
            </MenuItem>
          </NavLink>
          <NavLink to="/students">
            <MenuItem icon={<School />}>
              Students
            </MenuItem>
          </NavLink>
          <NavLink to="/instructors">
            <MenuItem icon={<SpatialAudioOffIcon />}>
              Instructors
            </MenuItem>
          </NavLink>
          <NavLink to="/aircrafts">
            <MenuItem icon={<AirplanemodeActiveIcon />}>
              Aircrafts
            </MenuItem>
          </NavLink>
          <NavLink to="/schedules">
            <MenuItem icon={<EditCalendarIcon />}>
            schedules
            </MenuItem>
          </NavLink>
        </Menu>
      </Sidebar>
      <main>
        <div onClick={() => { collapseSidebar(); toggleSidebar() }} className={`sidebar--toggle__icon ${toggled ? 'collapsed':'open'}`}>
          {toggled ?  <KeyboardArrowLeft /> : <MenuRoundedIcon /> }
        </div>
      </main>
    </div>
  );
});
