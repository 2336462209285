import React, { useContext, useEffect, useState } from "react";
import { useParams } from "react-router";
import * as yup from "yup";
import swal from "sweetalert";

import withLayout from "../../../hoc/Layout";
import dayjs from "dayjs";

import { Input } from "../../../components/ui/form/Input";
import { Date } from "../../../components/ui/form/DatePicker";
import { DynamicForm } from "../../../components/ui/form/DynamicForm";
import { Form } from "../../../components/ui/form/Form";
import { Dropdown } from "../../../components/ui/form/Dropdown";
import { CommonContext } from "../../../context/Common.context";
import {
  AircraftContext,
  AircraftProvider,
} from "../../../context/Aircraft.context";

const AddAricraftForm = (props) => {
  const {
    initialValues,
    aircraftType,
    aircraftClass,
    category,
    commonContextData,
  } = props;
  const { id } = useParams();
  const [isLoaded, setLoaded] = useState(false);
  const defaultValues = initialValues;
  const [initialEditValues, setInitialEditValues] = useState(defaultValues);
  const [isEdit, setIsEdit] = useState(false);
  const aircraftContext = useContext(AircraftContext);
  const { aircraftList, aircraftCheck, aircraftCategory } = aircraftContext.state;

  const aircraftValidation = {
    registration_number: yup
      .string()
      .required("Registration number is required"),
    date_of_registration: yup
      .string()
      .required("Date of registration is required"),
    msn: yup.string().required("msn is required"),
    year_of_manufacture: yup
      .string()
      .required("Year of manufacture is required."),
    type_of_aircraft: yup.string().required("You must select an option!"),
    class_of_aircraft: yup.string().required("You must select an option!"),
    category: yup.string().required("You must select an option!"),
    number_of_seats: yup.string().required("Number of seats is required."),
    flown_hours: yup.string().required("Flown hours is required."),
    number_of_engines: yup.string().required("Number of engines is required."),
    make: yup.string().required("make is required."),
    fuel_grade: yup.string().required("Fuel grade is required."),
    physical_class_engine: yup
      .string()
      .required("Engine physical class is required."),
    max_continous_power: yup
      .string()
      .required("Max continous power is required."),
    engine_max_power: yup.string().required("Engine max power is required."),
    fuel_capacity: yup.string().required("Fuel capacity is required."),
    unusable_fuel: yup.string().required("Unusable fuel is required."),
    oil_capacity: yup.string().required("Oil capacity is required."),
    propeller_type: yup.string().required("Propeller type is required."),
    max_total_weight: yup.string().required("Max total weight is required."),
    basic_empty_weight: yup
      .string()
      .required("Basic empty weight is required."),
    arm_basic_empty_weight: yup
      .string()
      .required("Arm basic empty weight is required."),
    actual_weight_crew: yup
      .string()
      .required("Actual crew weight is required."),
    passenger: yup.string().required("passenger is required."),
    max_fwdc_of_g: yup.string().required("Max FWD c of g is required."),
    max_aftc_of_g: yup.string().required("Max AFT c of g is required."),
    aircraftchecks: yup.array(
      yup.object({
        type_of_check: yup.string().required("time_choice is required"),
        ac_hours_at_check: yup.string().required("pilot_role is required"),
        check_next: yup.string().required("next check is required"),
        valid_till: yup.string().required("Valid till date is required"),
        last_check: yup.string().required("Last check on date is required"),
      })
    ),
  };

  const addAircraft = async (formData) => {
    const { date_of_registration, aircraftchecks, year_of_manufacture } =
      formData;
    const finalaircraftchecks = aircraftchecks.map((item) => {
      item.check_next = dayjs(item.check_next).format("YYYY-MM-DD");
      item.valid_till = dayjs(item.valid_till).format("YYYY-MM-DD");
      item.last_check = dayjs(item.last_check).format("YYYY-MM-DD");
      return item;
    });

    const finalFormData = {
      ...formData,
      date_of_registration: dayjs(date_of_registration).format("YYYY-MM-DD"),
      year_of_manufacture: dayjs(year_of_manufacture).format("YYYY-MM-DD"),
      aircraftchecks: finalaircraftchecks,
    };

    await aircraftContext.addAircraft(finalFormData, "/aircrafts");
  };

  useEffect(() => {
    if (id) {
      setIsEdit(true);
    }
    if (!isLoaded) {
      (async () => {
        await aircraftContext.getAircraftCheck();
        await aircraftContext.getAircraftCategory();
        const res = await aircraftContext.getAircraftList();
        const list = res?.data[0]?.data || [];
        if (list) {
          const aircarft = list.filter((aircarft) => aircarft.id === id);
          setInitialEditValues(aircarft[0]);
        }
        setLoaded(true);
      })();
    }
  }, []);

  return (
    <>
      {isLoaded ? (
        <Form
          onSubmit={(formData) => addAircraft(formData)}
          defaultValues={isEdit ? initialEditValues : defaultValues}
          validationSchema={aircraftValidation}
          submitButtonText="Submit"
          redirectUrl="/aircrafts"
        >
          <Input
            type="text"
            name="registration_number"
            placeholder="Registration number"
            fullWidth
          />
          <Date label="Date of Registration" name="date_of_registration" />
          <Input type="text" name="msn" placeholder="MSN number" fullWidth />
          <Date label="Year of Manufacture" name="year_of_manufacture" />
          <Dropdown
            fieldlabel="Aircraft Type"
            placeholder="Aircraft Type"
            name="type_of_aircraft"
            options={aircraftType}
            optionDisplayKey="aircraft_type"
            optionValueKey="id"
            defaultValue={
              isEdit
                ? {
                    label: initialEditValues.aircraft_type_name,
                    value: initialEditValues.aircraft_type,
                  }
                : null
            }
          />
          <Dropdown
            fieldlabel="Class 0f Aircraft"
            placeholder="Class 0f Aircraft"
            name="class_of_aircraft"
            options={aircraftClass}
            optionDisplayKey="aircraft_class"
            optionValueKey="id"
            defaultValue={
              isEdit
                ? {
                    label: initialEditValues.aircraft_class_name,
                    value: initialEditValues.aircraft_class,
                  }
                : null
            }
          />
          <Dropdown
            fieldlabel="Category"
            placeholder="Category"
            name="category"
            options={aircraftCategory}
            optionDisplayKey="category_name"
            optionValueKey="id"
            defaultValue={
              isEdit
                ? {
                    label: initialEditValues.category_name,
                    value: initialEditValues.category,
                  }
                : null
            }
          />
          <Input
            type="number"
            name="number_of_seats"
            placeholder="Number of seats"
            fullWidth
          />
          <Input
            type="number"
            name="flown_hours"
            placeholder="Flown hours"
            fullWidth
          />
          <div class="subheader">
            <h2>Powerplant</h2>
          </div>
          <Input
            type="text"
            name="number_of_engines"
            placeholder="Number of engines"
            fullWidth
          />
          <Input type="text" name="make" placeholder="Make" fullWidth />
          <Input
            type="text"
            name="fuel_grade"
            placeholder="Fuel grade"
            fullWidth
          />
          <div class="subheader">
            <h2>Engine Limits</h2>
          </div>
          <Dropdown
            fieldlabel="Physical class engine"
            placeholder="Physical class engine"
            name="physical_class_engine"
            options={category}
            optionDisplayKey="category"
            optionValueKey="id"
            defaultValue={
              isEdit
                ? {
                    label: initialEditValues.category_name,
                    value: initialEditValues.category,
                  }
                : null
            }
          />
          <Input
            type="text"
            name="max_continous_power"
            placeholder="Max continous power"
            fullWidth
          />
          <Input
            type="text"
            name="engine_max_power"
            placeholder="Engine continous power"
            fullWidth
          />
          <div class="subheader">
            <h2>Fuel and Oil Capacity</h2>
          </div>
          <Input
            type="text"
            name="fuel_capacity"
            placeholder="Fuel capacity"
            fullWidth
          />
          <Input
            type="text"
            name="unusable_fuel"
            placeholder="Unusable fuel"
            fullWidth
          />
          <Input
            type="text"
            name="oil_capacity"
            placeholder="Oil capacity"
            fullWidth
          />
          <Input
            type="text"
            name="propeller_type"
            placeholder="Propeller type"
            fullWidth
          />
          <div class="subheader">
            <h2>Weights</h2>
          </div>
          <Input
            type="text"
            name="max_total_weight"
            placeholder="Max total weight"
            fullWidth
          />
          <Input
            type="text"
            name="basic_empty_weight"
            placeholder="Basic empty weight"
            fullWidth
          />
          <div class="subheader">
            <h2>ARM</h2>
          </div>
          <Input
            type="text"
            name="arm_basic_empty_weight"
            placeholder="Arm Basic empty weight"
            fullWidth
          />
          <Input
            type="text"
            name="actual_weight_crew"
            placeholder="Crew weight"
            fullWidth
          />
          <Input
            type="number"
            name="passenger"
            placeholder="Passenger"
            fullWidth
          />
          <Input
            type="text"
            name="baggage_area_a"
            placeholder="Baggage area A"
            fullWidth
          />
          <Input
            type="text"
            name="baggage_area_b"
            placeholder="Baggage area B"
            fullWidth
          />
          <Input type="text" name="arm_fuel" placeholder="Fuel" fullWidth />
          <div class="subheader">
            <h2>Checks</h2>
          </div>
          <DynamicForm
            name="aircraftchecks"
            addLabel="Add More Checks"
            isDynamicForm
          >
            <Dropdown
              fieldlabel="Type of check"
              placeholder="Type of check"
              name="type_of_check"
              options={aircraftCheck}
              optionDisplayKey="check_name"
              optionValueKey="id"
            />
            <Date label="Last check on" name="last_check" />
            <Date label="Valid till" name="valid_till" />
            <Input
              type="number"
              name="ac_hours_at_check"
              placeholder="A/C Hours at Check"
              fullWidth
            />
            <Date label="Next check" name="check_next" />
          </DynamicForm>
          <div class="subheader">
            <h2>C of G</h2>
          </div>
          <Input
            type="text"
            name="max_fwdc_of_g"
            placeholder="Max Fwd c of g"
            fullWidth
          />
          <Input
            type="text"
            name="max_aftc_of_g"
            placeholder="max_aftc_of_g"
            fullWidth
          />
          <Input type="text" name="comments" placeholder="Comments" fullWidth />
        </Form>
      ) : null}
    </>
  );
};

const AddAircraft = () => {
  const [isLoaded, setLoaded] = useState(false);
  const commonContextData = useContext(CommonContext);
  const { choiceFields } = commonContextData.state;
  const { aircraft_type, aircraft_class, category_choices } = choiceFields;
  const initialValues = {
    aircraftchecks: [
      {
        // flight_experience_id: null,
        type_of_check: null,
        ac_hours_at_check: "",
      },
    ],
  };

  useEffect(() => {
    if (!isLoaded) {
      (async () => {
        await commonContextData.getChoiceFields();
        setLoaded(true);
      })();
    }
  }, []);
  return (
    <div className="container-fluid">
      <div className="page-header">
        <h2>Add Aircarft</h2>
      </div>
      <div className="page-content grid2_2">
        <AircraftProvider>
          {isLoaded ? (
            <AddAricraftForm
              initialValues={initialValues}
              commonContextData={commonContextData}
              aircraftType={aircraft_type}
              aircraftClass={aircraft_class}
              category={category_choices}
            />
          ) : null}
        </AircraftProvider>
      </div>
    </div>
  );
};

export default withLayout(AddAircraft);
