import React from "react";
import { useNavigate } from "react-router";

import withLayout from "../../hoc/Layout";
import { AircraftProvider } from "../../context/Aircraft.context";
import { AircraftList } from "./AircraftList";
import { Button } from "../../components/ui/Button";

const Aircrafts = (props) => {
  const navigate = useNavigate();

  return (
    <div className="container-fluid">
      <div className="page-header">
        <h2>Aircarfts</h2>
        <Button variant="outlined" onClick={() => navigate("add")} size="small">
          Add Aircraft
        </Button>
      </div>
      <div>
        <AircraftProvider>
          <AircraftList />
        </AircraftProvider>
      </div>
    </div>
  );
};

export default withLayout(Aircrafts);
