import React, { useContext, useEffect, useMemo, useState } from "react";
import { useParams } from "react-router";

import * as yup from "yup";
import dayjs from "dayjs";
import { isEmpty } from "lodash";

import { Button } from "../../../../../components/ui/Button";
import { Date } from "../../../../../components/ui/form/DatePicker";
import { Form } from "../../../../../components/ui/form/Form";
import { Spinner } from "../../../../../components/ui/Spinner";
import { Modal } from "../../../../../components/ui/Modal";
import { Table } from "../../../../../components/ui/Table";
import { CommonContext } from "../../../../../context/Common.context";
import { Dropdown } from "../../../../../components/ui/form/Dropdown";
import { Box, IconButton } from "@mui/material";
import { Edit, Delete } from "@mui/icons-material";
import swal from "sweetalert";
import { options } from "../../../../../utils/SelectOptions";

const ELPLicenseForm = ({
  levelChoices,
  defaultValues,
  tableData,
  commonContextData,
}) => {
  const [initialEditValues, setInitialEditValues] = useState(defaultValues);
  const [openModal, setOpenModal] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const { level } = options;
  const columns = useMemo(
    () => [
      {
        accessorKey: "level",
        header: "Level",
      },
      {
        accessorKey: "issue_date",
        header: "Issuance Date",
      },
      {
        accessorKey: "valid_date",
        header: "Valid Upto",
      },
    ],
    []
  );

  const validationSchema = {
    level: yup.string().required("Level is required"),
    issue_date: yup.string().required("Issuance Date is required"),
    valid_date: yup.string().required("Valid Upto date required"),
  };

  const submitForm = async (formData) => {
    const { user, issue_date, valid_date } = formData;

    const finalFormData = {
      ...formData,
      issue_date: dayjs(issue_date).format("YYYY-MM-DD"),
      valid_date: dayjs(valid_date).format("YYYY-MM-DD"),
    };
    if (isEdit) {
      finalFormData["user_id"] = user;
    }
    await commonContextData.updateUserELPDetails(
      {
        ...finalFormData,
      },
      handleClose
    );
  };

  useEffect(() => {
    console.log(tableData);
    if (!isEdit) {
      setInitialEditValues(defaultValues);
    }
  }, [isEdit]);

  const deactivate = (id, type, endpoint) => {
    let msg = `Are you sure you want to delete the ELP License?`;
    swal(msg, "", "warning", {
      buttons: {
        cancel: true,
        confirm: true,
      },
    }).then((val) => {
      if (val) {
        let alertMsg = `ELP License deleted successfully.`;
        commonContextData.deleteAction(id, type, endpoint, async () => {
          await commonContextData.getUserELPDetails(id);

          swal(alertMsg, "", "success", {
            button: "ok",
          });
        });
      }
    });
  };

  const handleClose = () => {
    setOpenModal(false);
    setIsEdit(false);
  };
  const handleOpen = () => setOpenModal(true);
  return (
    <>
      <div className="sub-header">
        <h3>ELP License</h3>
        <Button onClick={handleOpen}>Add ELP License</Button>
        <Modal open={openModal} closeModal={handleClose}>
          <div className="grid2_2">
            <h4>Add ELP License</h4>
            <Form
              onSubmit={(formData) => submitForm(formData)}
              defaultValues={isEdit ? initialEditValues : defaultValues}
              validationSchema={validationSchema}
              submitButtonText="Submit"
              redirectUrl="/students"
            >
              <Dropdown
                fieldlabel="Level"
                placeholder="Level"
                name="level"
                options={levelChoices}
                optionDisplayKey="level"
                optionValueKey="id"
                defaultValue={
                  isEdit
                    ? {
                        label: initialEditValues.level_name,
                        value: initialEditValues.level,
                      }
                    : null
                }
              />
              <Date label="Issuance Date" name="issue_date" />
              <Date label="Valid Upto" name="valid_date" />
            </Form>
          </div>
        </Modal>
      </div>
      <div className="tableCard">
        <Table
          data={tableData}
          columns={columns}
          enableColumnActions={false}
          enableColumnFilters={false}
          enableTopToolbar={false}
          enableRowActions
          positionActionsColumn="last"
          renderRowActions={({ cell, row, table }) => (
            <Box sx={{ display: "flex", flexWrap: "nowrap", gap: "8px" }}>
              <IconButton
                color="primary"
                onClick={() => {
                  setInitialEditValues(cell.row.original);
                  setIsEdit(true);
                  setOpenModal(true);
                }}
              >
                <Edit />
              </IconButton>
              <IconButton
                color="primary"
                onClick={() => {
                  console.log(row);
                  deactivate(row.original.id, "elp", "user-elp");
                }}
              >
                <Delete />
              </IconButton>
            </Box>
          )}
        />
      </div>
    </>
  );
};

export const ELPLicense = () => {
  const { id } = useParams();
  const commonContextData = useContext(CommonContext);
  const { userELPDetails, choiceFields } = commonContextData.state;
  const [isLoaded, setLoaded] = useState(false);
  const { level } = choiceFields;

  let initialValue = {
    user_id: id,
    level: "",
  };

  useEffect(() => {
    if (!isLoaded) {
      (async () => {
        await commonContextData.getUserELPDetails(id);
        setLoaded(true);
        console.log(userELPDetails);
      })();
    }
  }, [isLoaded]);

  return isLoaded ? (
    <ELPLicenseForm
      defaultValues={initialValue}
      tableData={userELPDetails}
      commonContextData={commonContextData}
      levelChoices={level}
    />
  ) : (
    <Spinner />
  );
};
