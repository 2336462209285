import React, { useContext, useEffect } from "react";
import withLayout from "../../../hoc/Layout";
import { useParams } from "react-router";
import { CommonContext } from "../../../context/Common.context";

const InstructorDetails = (props) => {
  const { id } = useParams();
  const commonContextData = useContext(CommonContext);
  const { userDetail } = commonContextData.state;

  useEffect(() => {
    (async () => {
      await commonContextData.getUserDetails(id);
    })();
  }, []);

  return (
    <div className="container-fluid">
      <h2>Basic Details</h2>
      <p>First name: {userDetail?.profile?.first_name || "-"}</p>
      <h2>Emergency Contact Details</h2>
      <h2>Other Details</h2>
    </div>
  );
};

export default withLayout(InstructorDetails);
