import React, { useContext, useEffect, useMemo, useState } from "react";
import { useParams } from "react-router";

import * as yup from "yup";
import moment from "moment";
import { isEmpty } from "lodash";

import EventDispatcher from "../../../../../utils/EventDispatcher";
import { Button } from "../../../../../components/ui/Button";
import { Input } from "../../../../../components/ui/form/Input";
import { Date } from "../../../../../components/ui/form/DatePicker";
import { Form } from "../../../../../components/ui/form/Form";
import { Spinner } from "../../../../../components/ui/Spinner";
import { Modal } from "../../../../../components/ui/Modal";
import { Table } from "../../../../../components/ui/Table";
import { CommonContext } from "../../../../../context/Common.context";
import { Dropdown } from "../../../../../components/ui/form/Dropdown";
import { Box, IconButton } from "@mui/material";
import { Edit, Delete } from "@mui/icons-material";
import swal from "sweetalert";
import { options } from "../../../../../utils/SelectOptions";

const LicenseForm = ({
  licenseChoices,
  categoryChoices,
  countries,
  defaultValues,
  tableData,
  commonContextData,
}) => {
  const [initialEditValues, setInitialEditValues] = useState(defaultValues);
  const [openModal, setOpenModal] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  // const { license_choices, category_choices, countries } = options;
  const columns = useMemo(
    () => [
      {
        accessorKey: "license_number",
        header: "License No.",
      },
      {
        accessorKey: "license_type_name",
        header: "License Type",
      },
      {
        accessorKey: "category_name",
        header: "Category",
      },
      {
        accessorKey: "valid_from",
        header: "Valid From",
      },
      {
        accessorKey: "valid_to",
        header: "Valid Upto",
      },
      {
        accessorKey: "country_name",
        header: "Issuing Country",
      },
    ],
    []
  );

  const validationSchema = {
    license_number: yup.string().required("License number is required"),
    license_type: yup.string().required("License type is required"),
    category: yup.string().required("Category is required"),
    valid_from: yup.string().required("Valid From date is required"),
    valid_to: yup.string().required("Valid Upto date is required"),
    issue_country: yup.string().required("License issue country is required"),
  };

  const submitForm = async (formData) => {
    const finalFormData = {
      ...formData,
      valid_from: moment(formData.valid_from).format("YYYY-MM-DD"),
      valid_to: moment(formData.valid_to).format("YYYY-MM-DD"),
    };
    console.log(finalFormData);

    if (isEdit) {
      finalFormData["user_id"] = formData.user;
    }
    await commonContextData.updateUserLicenseProfile(
      {
        ...finalFormData,
      },
      handleClose
    );
  };

  useEffect(() => {
    if (!isEdit) {
      setInitialEditValues(defaultValues);
    }
  }, [isEdit]);

  const deactivate = (id, type, endpoint) => {
    let msg = `Are you sure you want to delete the License?`;
    swal(msg, "", "warning", {
      buttons: {
        cancel: true,
        confirm: true,
      },
    }).then((val) => {
      if (val) {
        let alertMsg = `License deleted successfully.`;
        commonContextData.deleteAction(id, type, endpoint, async () => {
          await commonContextData.getUserLicenseProfile(id);

          swal(alertMsg, "", "success", {
            button: "ok",
          });
        });
      }
    });
  };

  const handleClose = () => {
    setOpenModal(false);
    setIsEdit(false);
  };
  const handleOpen = () => setOpenModal(true);

  return (
    <>
      <div className="sub-header">
        <h3>License</h3>
        <Button onClick={handleOpen}>Add License</Button>
        <Modal open={openModal} closeModal={handleClose}>
          <div className="grid2_2">
            <h4>Add License</h4>
            <Form
              onSubmit={(formData) => submitForm(formData)}
              defaultValues={isEdit ? initialEditValues : defaultValues}
              validationSchema={validationSchema}
              submitButtonText="Submit"
              redirectUrl="/instructors"
            >
              <Input
                type="text"
                name="license_number"
                placeholder="License number"
                fullWidth
              />
              <Dropdown
                fieldlabel="License Type"
                placeholder="License Type"
                name="license_type"
                options={licenseChoices}
                optionDisplayKey="type"
                optionValueKey="id"
                defaultValue={
                  isEdit
                    ? {
                        label: initialEditValues.license_type_name,
                        value: initialEditValues.license_type,
                      }
                    : null
                }
              />
              <Dropdown
                fieldlabel="Category"
                placeholder="Category"
                name="category"
                options={categoryChoices}
                optionDisplayKey="category"
                optionValueKey="id"
                defaultValue={
                  isEdit
                    ? {
                        label: initialEditValues.category_name,
                        value: initialEditValues.category,
                      }
                    : null
                }
              />
              <Date label="Valid From" name="valid_from" fullWidth />
              <Date label="Valid Upto" name="valid_to" />
              <Dropdown
                fieldlabel="Issue country"
                placeholder="Issue country"
                name="issue_country"
                options={categoryChoices}
                optionDisplayKey="country_name"
                optionValueKey="code"
                defaultValue={
                  isEdit
                    ? {
                        label: initialEditValues.country_name,
                        value: initialEditValues.issue_country,
                      }
                    : null
                }
              />
            </Form>
          </div>
        </Modal>
      </div>
      <div className="tableCard">
        <Table
          data={tableData}
          columns={columns}
          enableColumnActions={false}
          enableColumnFilters={false}
          enableTopToolbar={false}
          enableRowActions
          positionActionsColumn="last"
          renderRowActions={({ cell, row, table }) => (
            <Box sx={{ display: "flex", flexWrap: "nowrap", gap: "8px" }}>
              <IconButton
                color="primary"
                onClick={() => {
                  setInitialEditValues(cell.row.original);
                  setIsEdit(true);
                  setOpenModal(true);
                }}
              >
                <Edit />
              </IconButton>
              <IconButton
                color="primary"
                onClick={() => {
                  console.log(row);
                  deactivate(row.original.id, "license", "user-license");
                }}
              >
                <Delete />
              </IconButton>
            </Box>
          )}
        />
      </div>
    </>
  );
};

export const License = () => {
  const { id } = useParams();
  const commonContextData = useContext(CommonContext);
  const { userLicense, choiceFields } = commonContextData.state;
  const [isLoaded, setLoaded] = useState(false);
  const { license_choices, category_choices, countries } = choiceFields;

  let initialValue = {
    user_id: id,
    license_number: "",
    issue_country: "",
    license_document: "",
    license_type: "",
    category: "",
  };

  useEffect(() => {
    if (!isLoaded) {
      (async () => {
        await commonContextData.getUserLicenseProfile(id);
        setLoaded(true);
      })();
    }
    console.log(initialValue, userLicense);
  }, [isLoaded]);

  return isLoaded ? (
    <LicenseForm
      defaultValues={initialValue}
      tableData={userLicense}
      commonContextData={commonContextData}
      licenseChoices={license_choices}
      categoryChoices={category_choices}
      countries={countries}
    />
  ) : (
    <Spinner />
  );
};
