import React, { useContext, useEffect, useMemo, useState } from "react";
import { useParams } from "react-router";

import * as yup from "yup";
import dayjs from "dayjs";
import { isEmpty } from "lodash";

import EventDispatcher from "../../../../../utils/EventDispatcher";
import { Button } from "../../../../../components/ui/Button";
import { Input } from "../../../../../components/ui/form/Input";
import { Date } from "../../../../../components/ui/form/DatePicker";
import { Form } from "../../../../../components/ui/form/Form";
import { Spinner } from "../../../../../components/ui/Spinner";
import { Modal } from "../../../../../components/ui/Modal";
import { Table } from "../../../../../components/ui/Table";
import { CommonContext } from "../../../../../context/Common.context";
import { Dropdown } from "../../../../../components/ui/form/Dropdown";
import { Box, IconButton } from "@mui/material";
import { Edit, Delete } from "@mui/icons-material";
import swal from "sweetalert";
import { options } from "../../../../../utils/SelectOptions";

const AircraftEndorsementForm = ({
  roleChoices,
  aircraftClass,
  aircraftType,
  defaultValues,
  tableData,
  commonContextData,
}) => {
  const [initialEditValues, setInitialEditValues] = useState(defaultValues);
  const [openModal, setOpenModal] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const { aircraft_class, aircraft_type, role_choice } = options;
  const columns = useMemo(
    () => [
      {
        accessorKey: "aircraft_type_name",
        header: "Aircraft Type",
      },
      {
        accessorKey: "role_name",
        header: "Role",
      },
      {
        accessorKey: "aircraft_class_name",
        header: "Aircraft Class",
      },
      {
        accessorKey: "endorsement_date",
        header: "Aircraft Endorsement Date",
      },
    ],
    []
  );

  const validationSchema = {
    aircraft_type: yup.string().required("Aircraft type is required"),
    role: yup.string().required("Role is required"),
    aircraft_class: yup.string().required("Aircraft class is required"),
    endorsement_date: yup
      .string()
      .required("Aircraft Endorsement Date is required"),
  };

  const submitForm = async (formData) => {
    const { endorsement_date } = formData;

    const finalFormData = {
      ...formData,
      endorsement_date: dayjs(endorsement_date).format("YYYY-MM-DD"),
    };

    if (isEdit) {
      finalFormData["user_id"] = formData.user;
    }
    await commonContextData.updateAircraftEndorsement(
      {
        ...finalFormData,
      },
      handleClose
    );
  };

  useEffect(() => {
    if (!isEdit) {
      setInitialEditValues(defaultValues);
    }
  }, [isEdit]);

  const deactivate = (id, endpoint, type) => {
    let msg = `Are you sure you want to delete the Aircraft Endorsement?`;
    swal(msg, "", "warning", {
      buttons: {
        cancel: true,
        confirm: true,
      },
    }).then((val) => {
      if (val) {
        let alertMsg = `Aircraft Endorsement deleted successfully.`;
        commonContextData.deleteAction(id, endpoint, type, async () => {
          await commonContextData.getAircraftEndorsement(id);

          swal(alertMsg, "", "success", {
            button: "ok",
          });
        });
      }
    });
  };

  const handleClose = () => {
    setOpenModal(false);
    setIsEdit(false);
  };
  const handleOpen = () => setOpenModal(true);

  return (
    <>
      <div className="sub-header">
        <h3>Aircraft Endorsement</h3>
        <Button onClick={handleOpen}>Add Aircraft Endorsement</Button>
        <Modal open={openModal} closeModal={handleClose}>
          <div className="grid2_2">
            <h4>Add Aircraft Endorsement</h4>
            <Form
              onSubmit={(formData) => submitForm(formData)}
              defaultValues={isEdit ? initialEditValues : defaultValues}
              validationSchema={validationSchema}
              submitButtonText="Submit"
              redirectUrl="/students"
            >
              <Dropdown
                fieldlabel="Aircraft Type"
                placeholder="Aircraft Type"
                name="aircraft_type"
                options={aircraftType}
                optionDisplayKey="aircraft_type"
                optionValueKey="id"
                defaultValue={
                  isEdit
                    ? {
                        label: initialEditValues.aircraft_type_name,
                        value: initialEditValues.aircraft_type,
                      }
                    : null
                }
              />
              <Dropdown
                fieldlabel="Role"
                placeholder="Role"
                name="role"
                options={roleChoices}
                optionDisplayKey="role_name"
                optionValueKey="id"
                defaultValue={
                  isEdit
                    ? {
                        label: initialEditValues.role_name,
                        value: initialEditValues.role,
                      }
                    : null
                }
              />
              <Dropdown
                fieldlabel="Aircraft class"
                placeholder="Aircraft class"
                name="aircraft_class"
                options={aircraftClass}
                optionDisplayKey="aircraft_class"
                optionValueKey="id"
                defaultValue={
                  isEdit
                    ? {
                        label: initialEditValues.aircraft_class_name,
                        value: initialEditValues.aircraft_class,
                      }
                    : null
                }
              />
              <Date label="Aircraft Endorsement Date" name="endorsement_date" />
            </Form>
          </div>
        </Modal>
      </div>
      <div className="tableCard">
        <Table
          data={tableData}
          columns={columns}
          enableColumnActions={false}
          enableColumnFilters={false}
          enableTopToolbar={false}
          enableRowActions
          positionActionsColumn="last"
          renderRowActions={({ cell, row, table }) => (
            <Box sx={{ display: "flex", flexWrap: "nowrap", gap: "8px" }}>
              <IconButton
                color="primary"
                onClick={() => {
                  setInitialEditValues(cell.row.original);
                  setIsEdit(true);
                  setOpenModal(true);
                }}
              >
                <Edit />
              </IconButton>
              <IconButton
                color="primary"
                onClick={() => {
                  console.log(row);
                  deactivate(
                    row.original.id,
                    "endorsement",
                    "aircraft-endorsement"
                  );
                }}
              >
                <Delete />
              </IconButton>
            </Box>
          )}
        />
      </div>
    </>
  );
};

export const AircraftEndorsement = () => {
  const { id } = useParams();
  const commonContextData = useContext(CommonContext);
  const { userAircraftEndorsementDetails, choiceFields } =
    commonContextData.state;
  const [isLoaded, setLoaded] = useState(false);
  const { aircraft_type, aircraft_class, role_choice } = choiceFields;

  let initialValue = {
    user_id: id,
    aircraft_type: "",
    role: null,
    aircraft_class: null,
  };

  useEffect(() => {
    if (!isLoaded) {
      (async () => {
        await commonContextData.getAircraftEndorsement(id);
        setLoaded(true);
      })();
    }
  }, [isLoaded]);

  return isLoaded ? (
    <AircraftEndorsementForm
      defaultValues={initialValue}
      tableData={userAircraftEndorsementDetails}
      commonContextData={commonContextData}
      aircraftType={aircraft_type}
      aircraftClass={aircraft_class}
      roleChoices={role_choice}
    />
  ) : (
    <Spinner />
  );
};
