import React, { useContext, useEffect, useMemo, useState } from "react";
import { useParams } from "react-router";

import * as yup from "yup";
import dayjs from "dayjs";
import { isEmpty } from "lodash";

import EventDispatcher from "../../../../../utils/EventDispatcher";
import { Button } from "../../../../../components/ui/Button";
import { Input } from "../../../../../components/ui/form/Input";
import { Date } from "../../../../../components/ui/form/DatePicker";
import { Form } from "../../../../../components/ui/form/Form";
import { Spinner } from "../../../../../components/ui/Spinner";
import { Modal } from "../../../../../components/ui/Modal";
import { Table } from "../../../../../components/ui/Table";
import { CommonContext } from "../../../../../context/Common.context";
import { Dropdown } from "../../../../../components/ui/form/Dropdown";
import { Box, IconButton } from "@mui/material";
import { Edit, Delete } from "@mui/icons-material";
import swal from "sweetalert";
import { options } from "../../../../../utils/SelectOptions";

const InstrumentRatingForm = ({
  aircraftType,
  defaultValues,
  tableData,
  commonContextData,
}) => {
  const [initialEditValues, setInitialEditValues] = useState(defaultValues);
  const [openModal, setOpenModal] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const { aircraft_type } = options;
  const columns = useMemo(
    () => [
      {
        accessorKey: "instrument_number",
        header: "IR NO.",
      },
      {
        accessorKey: "aircraft_type_name",
        header: "Aircraft Type",
      },
      {
        accessorKey: "issue_date",
        header: "Initial Issuance Date",
      },
      {
        accessorKey: "valid_upto_initial",
        header: "Valid upto initial",
      },
      {
        accessorKey: "valid_upto",
        header: "Valid Upto",
      },
      {
        accessorKey: "renewal_date",
        header: "Renewal Date",
      },
    ],
    []
  );

  const validationSchema = {
    instrument_number: yup.string().required("Instrument number is required"),
    aircraft_type: yup.string().required("Aircraft type is required"),
    issue_date: yup.string().required("Initial Issuance Date is required"),
    valid_upto_initial: yup
      .string()
      .required("Intial issuance Valid upto date is required"),
    renewal_date: yup.string().required("Renewal Date is required"),
    valid_upto: yup.string().required("Renewal valid upto date is required"),
  };

  const submitForm = async (formData) => {
    const { issue_date, renewal_date, valid_upto, valid_upto_initial } =
      formData;

    const finalFormData = {
      ...formData,
      issue_date: dayjs(issue_date).format("YYYY-MM-DD"),
      renewal_date: dayjs(renewal_date).format("YYYY-MM-DD"),
      valid_upto: dayjs(valid_upto).format("YYYY-MM-DD"),
      valid_upto_initial: dayjs(valid_upto_initial).format("YYYY-MM-DD"),
    };

    if (isEdit) {
      finalFormData["user_id"] = formData.user;
    }
    await commonContextData.updateInstrumentRating(
      {
        ...finalFormData,
      },
      handleClose
    );
  };

  useEffect(() => {
    if (!isEdit) {
      setInitialEditValues(defaultValues);
    }
  }, [isEdit]);

  const deactivate = (id, endpoint, type) => {
    let msg = `Are you sure you want to delete the Instrument rating?`;
    swal(msg, "", "warning", {
      buttons: {
        cancel: true,
        confirm: true,
      },
    }).then((val) => {
      if (val) {
        let alertMsg = `Instrument rating deleted successfully.`;
        commonContextData.deleteAction(id, endpoint, type, async () => {
          await commonContextData.getInstrumentRating(id);

          swal(alertMsg, "", "success", {
            button: "ok",
          });
        });
      }
    });
  };

  const handleClose = () => {
    setOpenModal(false);
    setIsEdit(false);
  };
  const handleOpen = () => setOpenModal(true);

  return (
    <>
      <div className="sub-header">
        <h3>Instrument Rating</h3>
        <Button onClick={handleOpen}>Add Instrument Rating</Button>
        <Modal open={openModal} closeModal={handleClose}>
          <div className="grid2_2">
            <h4>Add Instrument Rating</h4>
            <Form
              onSubmit={(formData) => submitForm(formData)}
              defaultValues={isEdit ? initialEditValues : defaultValues}
              validationSchema={validationSchema}
              submitButtonText="Submit"
              redirectUrl="/instructors"
            >
              <Input
                type="text"
                name="instrument_number"
                placeholder="instrument number"
                fullWidth
              />
              <Dropdown
                fieldlabel="Aircraft type"
                placeholder="Aircraft type"
                name="aircraft_type"
                options={aircraftType}
                optionDisplayKey="aircraft_type"
                optionValueKey="id"
                defaultValue={
                  isEdit
                    ? {
                        label: initialEditValues.aircraft_type_name,
                        value: initialEditValues.aircraft_type,
                      }
                    : null
                }
              />
              <Date label="Initial Issuance Date" name="issue_date" />
              <Date label="Valid Upto" name="valid_upto_initial" />
              <Date label="Renewal Date" name="renewal_date" />
              <Date label="Valid Upto" name="valid_upto" />
            </Form>
          </div>
        </Modal>
      </div>
      <div className="tableCard">
        <Table
          data={tableData}
          columns={columns}
          enableColumnActions={false}
          enableColumnFilters={false}
          enableTopToolbar={false}
          enableRowActions
          positionActionsColumn="last"
          renderRowActions={({ cell, row, table }) => (
            <Box sx={{ display: "flex", flexWrap: "nowrap", gap: "8px" }}>
              <IconButton
                color="primary"
                onClick={() => {
                  setInitialEditValues(cell.row.original);
                  setIsEdit(true);
                  setOpenModal(true);
                }}
              >
                <Edit />
              </IconButton>
              <IconButton
                color="primary"
                onClick={() => {
                  console.log(row);
                  deactivate(
                    row.original.id,
                    "instrument",
                    "instrument-rating"
                  );
                }}
              >
                <Delete />
              </IconButton>
            </Box>
          )}
        />
      </div>
    </>
  );
};

export const InstrumentRating = () => {
  const { id } = useParams();
  const commonContextData = useContext(CommonContext);
  const { userInstrumentRatingDetails, choiceFields } = commonContextData.state;
  const [isLoaded, setLoaded] = useState(false);
  const { aircraft_type } = choiceFields;

  let initialValue = {
    user_id: id,
    instrument_number: "",
    aircraft_type: "",
  };

  useEffect(() => {
    if (!isLoaded) {
      (async () => {
        await commonContextData.getInstrumentRating(id);
        setLoaded(true);
      })();
    }
  }, [isLoaded]);

  return isLoaded ? (
    <InstrumentRatingForm
      defaultValues={initialValue}
      tableData={userInstrumentRatingDetails}
      commonContextData={commonContextData}
      aircraftType={aircraft_type}
    />
  ) : (
    <Spinner />
  );
};
