import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { Block, Delete, Edit, Visibility, Close } from "@mui/icons-material";
import { Box, IconButton } from "@mui/material";
import dayjs from "dayjs";
import localizedFormat from 'dayjs/plugin/localizedFormat'

import swal from "sweetalert";
import { Spinner } from "../../../components/ui/Spinner";

import { ScheduleContext } from "../../../context/Scheduler.context";
import { CommonContext } from "../../../context/Common.context";
import { AuthContext } from "../../../context/Auth.context";
import { Table } from "../../../components/ui/Table";
import { Scheduler } from "../../../components/ui/Calendar";
import { Modal } from "../../../components/ui/Modal";

export const ScheduleList = () => {
  dayjs.extend(localizedFormat);
  const [isLoaded, setLoaded] = useState(false);
  const scheduleContextData = useContext(ScheduleContext);
  const CommonContextData = useContext(CommonContext);
  const authContextData = useContext(AuthContext);
  const navigate = useNavigate();
  const { scheduleList } = scheduleContextData.state;
  const { userData } = authContextData.state;
  const [isAdminuser, setisAdminuser] = useState(false);
  const [scheduleListData, setScheduleListData] = useState([]);
   
  const [eventData, setEventData] = useState({});
  const [openModal, setOpenModal] = useState(false);
  const [eventDetailData, setEventDetailData] = useState({});


  useEffect(() => {    
    if (!isLoaded) {
      let isAdmin = userData.role === 3 ? true : false;
      console.log('new code executed');
      (async () => {
        setisAdminuser(isAdmin);
        
        await scheduleContextData.getSchedules(isAdmin);
        setScheduleListData(scheduleList);
        if(!isAdmin) {
          setLoaded(true);
        }
      })();
    }
  }, []);

  const createSchedule = (id) => {
    navigate(`create/${id}`);
  }
  const openEventDetails = (eventDetails) => {
    console.log(eventDetails);
    setEventDetailData(eventDetails);
    handleOpen();
  }
  const handleClose = () => setOpenModal(false);
  const handleOpen = () => setOpenModal(true);

  const setEventsData = (data) => {
    let eventDetails = {
      event: [],
      aircraftDetails: []
    }
    eventDetails.aircraftDetails = data.map((aircarft, index) => {
      const {type_of_aircraft_name, registration_number, category_name, flight_schedule } = aircarft;
      let events = flight_schedule.map((event, i) => {
        const {start_time, end_time, student, instructor, pilot_function, type_of_flight} = event;
        return {
          id: i,
          title: `${type_of_aircraft_name} | ${registration_number}`,
          start: new Date(start_time),
          end: new Date(end_time),
          resourceId: index + 1,
          eventDetails: {
            aircarft_name: type_of_aircraft_name,
            registration_number,
            category_name,
            student_name: `${student.first_name} ${student.last_name}`,
            instructor_name: `${instructor.first_name} ${instructor.last_name}`,
            pilot_function,
            type_of_flight,
          }
        }
      });
      eventDetails.event = [...events, ...eventDetails.event];
      return {
        resourceId: index + 1,
        resourceTitle: aircarft.type_of_aircraft_name,
        aircraft_id: aircarft.id,
        testRe: () => createSchedule(aircarft.id),
      };
    });
    return eventDetails;
  }

  useEffect(() => {
    setScheduleListData(isAdminuser && scheduleList.length ? scheduleList[0].flight_schedule : scheduleList);
    const eventDetailsData = setEventsData(scheduleList);
    setEventData(eventDetailsData);
    if(isAdminuser && scheduleList.length) {
      setLoaded(true);
    }
  }, [scheduleList]);
  

  return isLoaded ? (
    <div id="calendar-wrapper">
      <Scheduler eventData={eventData} openEventDetails={openEventDetails} />      
      <Modal open={openModal} className="Modal" closeModal={handleClose}>        
        <IconButton className="closeIcon-btn" onClick={handleClose} >
            <Close />
        </IconButton>
          <div className="event-details-modal">
            <h2>Event Details</h2>
            <div class="single-detail">
              <h3>Aircarft Name</h3>
              {eventDetailData.eventDetails?.aircarft_name}
            </div>
            
            <div class="single-detail">
              <h3>Registration Number</h3>
              {eventDetailData.eventDetails?.registration_number}
            </div>
            
            
            <div class="single-detail">
              <h3>Schedule Time</h3>
              {dayjs(eventDetailData?.start).format('LT')} to {dayjs(eventDetailData?.end).format('LT')}
              <br/>
              <i>{dayjs(eventDetailData?.end).format('L')}</i>
            </div>
            
            <div class="single-detail">
              <h3>Category</h3>
              {eventDetailData.eventDetails?.category_name}
            </div>
            
            <div class="single-detail">
              <h3>Student Name</h3>
              {eventDetailData.eventDetails?.student_name}
            </div>

            <div class="single-detail">
              <h3>Instructor Name</h3>
              {eventDetailData.eventDetails?.instructor_name}
            </div>
            
            <div class="single-detail">
              <h3>Pilot Type</h3>
              {eventDetailData.eventDetails?.pilot_function}
            </div>
            
            <div class="single-detail">
              <h3>Flight Type</h3>
              {eventDetailData.eventDetails?.type_of_flight}
            </div>
          </div>
        </Modal>
    </div>
  ) : (
    <Spinner />
  );
};
