import React, { useCallback, useMemo } from "react";
import { Calendar, Views, momentLocalizer } from 'react-big-calendar';
import moment from 'moment';

import { Button } from "../Button";

import "react-big-calendar/lib/css/react-big-calendar.css";
import "./calendar.scss"

const localizer = momentLocalizer(moment);

const MyResourceHeader = (props) => {
  return (
    <div>
      <h3 className="headerLabel">{props.label}</h3>      
      <Button variant="contained" onClick={props.resource.testRe} size="small">
          Create Schedule
        </Button>
    </div>
  );
};

export const Scheduler = ({eventData, ...props}) => {
  const { event, aircraftDetails } = eventData;
  const { views } = useMemo(
    () => ({
      views: ['day'],
      
    }),
    []
  )
  const components = useMemo(() => ({    
    resourceHeader: MyResourceHeader,
  }), []);

  const handleSelectEvent = useCallback(
    (event) => {
      props.openEventDetails(event);
    },
    []
  )

  return <Calendar
  localizer={localizer}
  defaultView={Views.DAY}
  events={event}
  resources={aircraftDetails}
  resourceIdAccessor="resourceId"
  resourceTitleAccessor="resourceTitle"
  startAccessor="start"
  endAccessor="end"
  onSelectEvent={handleSelectEvent}
  views={views}
  style={{ height: 450 }}
  components={components}
/>;
};
