import React, { useContext, useEffect } from "react";
import * as yup from "yup";
import swal from "sweetalert";

import withLayout from "../../../../hoc/Layout";
import { StudentContext } from "../../../../context/Student.context";
import { Input } from "../../../../components/ui/form/Input";
import { Form } from "../../../../components/ui/form/Form";
import { Dropdown } from "../../../../components/ui/form/Dropdown";
import { CommonContext } from "../../../../context/Common.context";

const AddStudent = () => {
  const studentContextData = useContext(StudentContext);
  const commonContextData = useContext(CommonContext);
  const { designations } = commonContextData.state;

  const defaultValues = {
    first_name: "",
    last_name: "",
    email: "",
    phone_no: "",
    password: "",
    designation: "",
  };

  const userValidation = {
    first_name: yup.string().required("First name is required"),
    last_name: yup.string().required("Last name is required"),
    email: yup
      .string()
      .required("Email is required")
      .email("Please enter a valid email address"),
    phone_no: yup.string().required("Phone no is required."),
    designation: yup.string().required("You must select an option!"),
    password: yup.string().required("Password is required"),
  };

  const addStudent = async (studentData) => {
    await studentContextData.addUser(studentData, "/students");
  };

  useEffect(() => {
    (async () => {
      await commonContextData.getDesignations();
    })();
  }, []);

  return (
    <div className="container-fluid">
      <div className="page-header">
        <h2>AddStudent</h2>
      </div>
      <div className="page-content grid2_2">
        <Form
          onSubmit={(studentData) => addStudent(studentData)}
          defaultValues={defaultValues}
          validationSchema={userValidation}
          submitButtonText="Submit"
          redirectUrl="/students"
        >
          <Input
            type="text"
            name="first_name"
            placeholder="First Name"
            fullWidth
          />
          <Input
            type="text"
            name="last_name"
            placeholder="Last Name"
            fullWidth
          />
          <Input type="text" name="email" placeholder="Email" fullWidth />
          <Input type="text" name="phone_no" placeholder="Phone No" fullWidth />
          <Input type="text" name="password" placeholder="Password" fullWidth />
          <Dropdown
            fieldlabel="Designation"
            placeholder="Designation"
            name="designation"
            options={designations}
            optionDisplayKey="designation_name"
            optionValueKey="id"
          />
        </Form>
      </div>
    </div>
  );
};

export default withLayout(AddStudent);
