import React, { useState } from "react";
import swal from "sweetalert";

import api from "../utils/axios";
import { useNavigate } from "react-router";

export const ScheduleContext = React.createContext(null);

const initialState = {
  aircraftList: [],
  scheduleList: [],
  studentList: [],
  instructorList: [],
};

export const ScheduleProvider = (props) => {
  const navigate = useNavigate();
  const [state, setState] = useState(initialState);
  const setScheduleData = (scheduleList) => {
    setState((prevState) => ({ ...prevState, scheduleList }));
  };
  const setStudentList = (studentList) => {
    setState((prevState) => ({ ...prevState, studentList }));
  };
  const setAircraftList = (aircraftList) => {
    setState((prevState) => ({ ...prevState, aircraftList }));
  };

  const setInstructorList = (instructorList) => {
    setState((prevState) => ({ ...prevState, instructorList }));
  };
  /** Get all Schedulers */
  const getSchedules = async (isAdmin) => {
    const adminEndpoint = 'roster/institute-flight-schedule-list/';
    const endpoint = 'schedule';
    try {
      const getScheduleData = await api.get(`flight-schedule/${isAdmin ? adminEndpoint : endpoint}`);
      const data = getScheduleData?.data[0].data || getScheduleData.data; 
      const { aircrafts } = getScheduleData?.data[0].data;
      setScheduleData((isAdmin ? aircrafts : data) || []);
    } catch (error) {
      console.log("Error", error);
    }
  };
  const getStudentList = async () => {
    try {
      const getStudentData = await api.get(`flight-schedule/roster/institute-student-list/`);

      setStudentList(getStudentData?.data[0].data || []);
    } catch (error) {
      console.log("Error", error);
    }
  };
  
  const getInstructorList = async () => {
    try {
      const getInstructorData = await api.get(`flight-schedule/roster/institute-instructor-list/`);

      setInstructorList(getInstructorData?.data[0].data || []);
    } catch (error) {
      console.log("Error", error);
    }
  };

  /** Add user (Scheduler/instructor) */
  const addSchedule = async (data, redirectURL) => {
    try {
      await api.post(`flight-schedule/schedule/add-flight-schedule/`, data);
      swal("New Schedule created Successfully", "", "success", {
        button: "ok",
      }).then(() => {
        navigate(redirectURL);
      });
    } catch (error) {
      swal("Error in creating a Schedule", "", "danger", {
        button: "ok",
      });
    }
  };

  /** Get all students */
  const getAircraftList = async () => {
    try {
      const aircraftList = await api.get(`aircraft/view-aircraft-list`);

      setAircraftList(aircraftList?.data[0].data || []);
    } catch (error) {
      console.log("Error", error);
    }
  };

  return (
    <ScheduleContext.Provider
      value={{
        state,
        getSchedules,
        getStudentList,
        getInstructorList,
        getAircraftList,
        addSchedule,
      }}
    >
      {props.children}
    </ScheduleContext.Provider>
  );
};
