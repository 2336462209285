import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router";
import * as yup from "yup";

import axios from "axios";
import { InputLabel, TextField, Grid, Button } from "@mui/material";
import { useFormik } from "formik";
import withLayout from "../../hoc/Layout";
import swal from "sweetalert";
const baseURL = process.env.REACT_APP_API_ENDPOINT;
const validationSchema = yup.object({
  name: yup.string().required("Institute name is required."),
});
const AddInstitute = () => {
  const navigate = useNavigate();
  const [message, setMessage] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [instituteDetails, setinstituteDetails] = useState({});
  useEffect(() => {
    document.title = "Add Institue";
  }, []);

  const handleFieldChange = (e) => {
    let { name, value } = e.target;
    setinstituteDetails((prevState) => ({ ...prevState, [name]: value }));
  };

  const baseURL = process.env.REACT_APP_API_ENDPOINT;
  const token = localStorage.access_token;
  let axiosConfig = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  };

  const onSubmit = (e) => {
    setIsLoading(true);
    axios
      .post(
        baseURL + `accounts/institute/add-institute/`,
        formik.values,
        axiosConfig
      )
      .then((response) => {
        setIsLoading(false);
        if (response.data[0].status) {
          let userId = response.data[0].data.id;
          navigate("/add-institute-user", { state: { userId } });
        }
      })
      .catch((error) => {
        console.log(error.response);
      });
  };

  const formik = useFormik({
    initialValues: {
      name: "",
      company_website: "",
      brand_name: "",
      registered_address: "",
      company_pan: "",
      company_tan: "",
      company_gstin: "",
    },
    validateOnBlur: true,
    onSubmit,
    validationSchema: validationSchema,
  });
  return (
    <div className="container-fluid">
      <div className="page-header">
        <h2>Add Student</h2>
      </div>
      <div className="page-content grid2_2">
        <Grid container>
          <Grid
            item
            md={6}
            style={{ marginTop: "auto", marginBottom: "auto", width: "100%" }}
          >
            <form
              className="form"
              method="POST"
              noValidate
              onSubmit={formik.handleSubmit}
            >
              <Grid container spacing={{ xs: 2, md: 3 }}>
                <Grid
                  item
                  md={6}
                  style={{
                    marginTop: "auto",
                    marginBottom: "auto",
                    width: "100%",
                  }}
                >
                  <InputLabel
                    className="InputLabel"
                    style={{
                      display: "flex",
                      fontSize: "1rem",
                      letterSpacing: "1px",
                      marginTop: "10px",
                      marginBottom: "-1rem",
                      textTransform: "capitalize",
                    }}
                  >
                    Name
                  </InputLabel>
                  <TextField
                    error={Boolean(formik.touched.name && formik.errors.name)}
                    fullWidth
                    helperText={formik.touched.name && formik.errors.name}
                    margin="normal"
                    name="name"
                    variant="outlined"
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    onKeyUp={handleFieldChange}
                    type="text"
                    value={formik.values.name}
                    size="small"
                    style={{ color: "#fff" }}
                  />
                </Grid>
                <Grid
                  item
                  md={6}
                  style={{
                    marginTop: "auto",
                    marginBottom: "auto",
                    width: "100%",
                  }}
                >
                  <InputLabel
                    className="InputLabel"
                    style={{
                      display: "flex",
                      fontSize: "1rem",
                      letterSpacing: "1px",
                      marginTop: "10px",
                      marginBottom: "-1rem",
                      textTransform: "capitalize",
                    }}
                  >
                    Company website
                  </InputLabel>
                  <TextField
                    fullWidth
                    size="small"
                    margin="normal"
                    name="company_website"
                    variant="outlined"
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    onKeyUp={handleFieldChange}
                    type="text"
                    value={formik.values.company_website}
                    style={{ color: "#fff" }}
                  />
                </Grid>
                <Grid
                  item
                  md={6}
                  style={{
                    marginTop: "auto",
                    marginBottom: "auto",
                    width: "100%",
                  }}
                >
                  <InputLabel
                    className="InputLabel"
                    style={{
                      display: "flex",
                      fontSize: "1rem",
                      letterSpacing: "1px",
                      marginTop: "10px",
                      marginBottom: "-1rem",
                      textTransform: "capitalize",
                    }}
                  >
                    Brand name
                  </InputLabel>
                  <TextField
                    fullWidth
                    size="small"
                    margin="normal"
                    name="brand_name"
                    variant="outlined"
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    onKeyUp={handleFieldChange}
                    type="text"
                    value={formik.values.brand_name}
                    style={{ color: "#fff" }}
                  />
                </Grid>
                <Grid
                  item
                  md={6}
                  style={{
                    marginTop: "auto",
                    marginBottom: "auto",
                    width: "100%",
                  }}
                >
                  <InputLabel
                    className="InputLabel"
                    style={{
                      display: "flex",
                      fontSize: "1rem",
                      letterSpacing: "1px",
                      marginTop: "10px",
                      marginBottom: "-1rem",
                      textTransform: "capitalize",
                    }}
                  >
                    Address
                  </InputLabel>
                  <TextField
                    fullWidth
                    size="small"
                    margin="normal"
                    name="registered_address"
                    variant="outlined"
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    onKeyUp={handleFieldChange}
                    type="text"
                    value={formik.values.registered_address}
                    style={{ color: "#fff" }}
                  />
                </Grid>
                <Grid
                  item
                  md={6}
                  style={{
                    marginTop: "auto",
                    marginBottom: "auto",
                    width: "100%",
                  }}
                >
                  <InputLabel
                    className="InputLabel"
                    style={{
                      display: "flex",
                      fontSize: "1rem",
                      letterSpacing: "1px",
                      marginTop: "10px",
                      marginBottom: "-1rem",
                      textTransform: "capitalize",
                    }}
                  >
                    Company PAN
                  </InputLabel>
                  <TextField
                    fullWidth
                    size="small"
                    margin="normal"
                    name="company_pan"
                    variant="outlined"
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    onKeyUp={handleFieldChange}
                    type="text"
                    value={formik.values.company_pan}
                    style={{ color: "#fff" }}
                  />
                </Grid>
                <Grid
                  item
                  md={6}
                  style={{
                    marginTop: "auto",
                    marginBottom: "auto",
                    width: "100%",
                  }}
                >
                  <InputLabel
                    className="InputLabel"
                    style={{
                      display: "flex",
                      fontSize: "1rem",
                      letterSpacing: "1px",
                      marginTop: "10px",
                      marginBottom: "-1rem",
                      textTransform: "capitalize",
                    }}
                  >
                    Company TAN
                  </InputLabel>
                  <TextField
                    fullWidth
                    size="small"
                    margin="normal"
                    name="company_tan"
                    variant="outlined"
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    onKeyUp={handleFieldChange}
                    type="text"
                    value={formik.values.company_tan}
                    style={{ color: "#fff" }}
                  />
                </Grid>
                <Grid
                  item
                  md={6}
                  style={{
                    marginTop: "auto",
                    marginBottom: "auto",
                    width: "100%",
                  }}
                >
                  <InputLabel
                    className="InputLabel"
                    style={{
                      display: "flex",
                      fontSize: "1rem",
                      letterSpacing: "1px",
                      marginTop: "10px",
                      marginBottom: "-1rem",
                      textTransform: "capitalize",
                    }}
                  >
                    Company GSTIN
                  </InputLabel>
                  <TextField
                    fullWidth
                    size="small"
                    margin="normal"
                    name="company_gstin"
                    variant="outlined"
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    onKeyUp={handleFieldChange}
                    type="text"
                    value={formik.values.company_gstin}
                    style={{ color: "#fff" }}
                  />
                </Grid>
              </Grid>

              <Button variant="contained" type="submit">
                Add Institute
              </Button>

              <Button
                variant="contained"
                style={{ marginLeft: "1rem" }}
                onClick={() => navigate("/dashboard")}
              >
                Cancel
              </Button>
            </form>
          </Grid>
        </Grid>
      </div>
    </div>
  );
};

export default withLayout(AddInstitute);
