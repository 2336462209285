import * as React from "react";
import Box from "@mui/material/Box";
import { Modal as MUIModal } from "@mui/material";

import { Button } from "../Button";
import EventDispatcher from "../../../utils/EventDispatcher";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "90%",
  maxWidth: "700px",
  overflow:'auto',
  maxHeight: "90%",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

let handleEditMode = () => {};
export const Modal = (props) => {
  const [open, setOpen] = React.useState(props.open || false);

  const handleOpen = () => {
    setOpen(true);
  }
  const handleClose = () => {
    handleEditMode(false);
    return setOpen(false);
  };

  EventDispatcher.on("closeModal", () => {
    handleClose();
  });

  EventDispatcher.on("openModal", ({ setEditMode, isEdit }) => {
    handleEditMode = setEditMode;
    handleEditMode(isEdit);
    // handleOpen();
  });

  return (
    <>
      {props.buttonLabel && <Button onClick={handleOpen}>{props.buttonLabel}</Button>}
      <MUIModal
        open={props.open || open}
        onClose={props.closeModal || handleClose}
        onBackdropClick={props.closeModal || handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>{props.children}</Box>
      </MUIModal>
    </>
  );
};
