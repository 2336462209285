export const options = {
    "pilot_type": [
      {
        label: 'copilot',
        id: 'copilot'
      }
    ],
    "flight_type": [
      {
        label: 'Training flight',
        id: 'trainingflight'
      }
    ]
  }