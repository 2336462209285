import React, { useContext, useEffect, useState } from "react";
import { useParams } from "react-router";
import * as yup from "yup";
import dayjs from "dayjs";

import { Input } from "../../../../components/ui/form/Input";
import { Date } from "../../../../components/ui/form/DatePicker";
import { Form } from "../../../../components/ui/form/Form";
import { CommonContext } from "../../../../context/Common.context";
import { Spinner } from "../../../../components/ui/Spinner";

const PersonalForm = ({ defaultValues, commonContextData, id }) => {
  const validationSchema = {
    first_name: yup.string().required("First name is required"),
    last_name: yup.string().required("Last name is required"),
    email: yup
      .string()
      .required("Email is required")
      .email("Please enter a valid email address"),
  };

  const submitProfile = async (profile_data) => {
    profile_data['date_of_birth'] = dayjs(profile_data['date_of_birth']).format('YYYY-MM-DD');

    await commonContextData.updateUserProfile(id, profile_data);
  };

  return (
    <Form
      onSubmit={(formData) => submitProfile(formData)}
      defaultValues={{ ...defaultValues }}
      validationSchema={validationSchema}
      submitButtonText="Submit"
      redirectUrl="/students"
    >
      <Input type="text" name="first_name" placeholder="First Name" fullWidth />
      <Input type="text" name="egca_id" placeholder="Egca Id" fullWidth />
      <Input
        type="text"
        name="middle_name"
        placeholder="Middle Name"
        fullWidth
      />
      <Input
        type="text"
        name="courses_applied_for"
        placeholder="Courses Applied For"
        fullWidth
      />
      <Input type="text" name="last_name" placeholder="Last Name" fullWidth />
      <Input
        type="text"
        name="user_address"
        placeholder="User Address"
        fullWidth
      />
      <Input type="text" name="gender" placeholder="Gender" fullWidth />
      <Input type="text" name="country" placeholder="Country" fullWidth />
      <Input
        type="text"
        name="nationality"
        placeholder="nationality"
        fullWidth
      />
      <Input type="text" name="state" placeholder="state" fullWidth />

      <Input type="text" name="email" placeholder="email" fullWidth />
      <Input type="text" name="city" placeholder="city" fullWidth />
      <Input type="text" name="phone_no" placeholder="phone_no" fullWidth />
      <Input type="text" name="pin" placeholder="pin" fullWidth />
      
      <Date label="Date of birth" name="date_of_birth" />
    </Form>
  );
};

export const Personal = (props) => {
  const { id } = useParams();
  const [isLoaded, setLoaded] = useState(false);
  const commonContextData = useContext(CommonContext);
  const { userDetail } = commonContextData.state;

  useEffect(() => {
    if (!isLoaded) {
      (async () => {
        await commonContextData.getUserDetails(id);
        setLoaded(true);
      })();
    }
  }, [isLoaded]);

  return isLoaded ? (
    <PersonalForm
      defaultValues={userDetail}
      commonContextData={commonContextData}
      id={id}
    />
  ) : (
    <Spinner />
  );
};
