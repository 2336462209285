import React, { useContext, useEffect, useMemo } from "react";
import { useNavigate } from "react-router-dom";

import { Block, Delete, Edit, Visibility } from "@mui/icons-material";
import { Box, IconButton } from "@mui/material";
import swal from "sweetalert";

import { InstructorContext } from "../../../context/Instructor.context";
import { CommonContext } from "../../../context/Common.context";
import { Table } from "../../../components/ui/Table";

export const InstructorList = () => {
  const instructorContextData = useContext(InstructorContext);
  const CommonContextData = useContext(CommonContext);
  const navigate = useNavigate();
  const { instructorList } = instructorContextData.state;

  useEffect(() => {
    instructorContextData.getInstructors();
  }, []);

  const deactivate = (id, actiontype, status) => {
    let msg = `Are you sure you want to 
                ${actiontype === "deactivate" ? "deactivate" : "delete"} 
                this instructor?`;
    swal(msg, "", "warning", {
      buttons: {
        cancel: true,
        confirm: true,
      },
    }).then((val) => {
      if (val) {
        let alertMsg = `Instructor ${
          actiontype === "deactivate" ? `${status ? "deactivated": "activated"}` : "deleted"
        } successfully.`;
        if (actiontype === "deactivate") {
          CommonContextData.statusUpdate(id, status, "instructor");
        } else {
          CommonContextData.deleteUser(id, "instructor");
        }
        swal(alertMsg, "", "success", {
          button: "ok",
        });
      }
    });
  };

  //should be memoized or stable
  const columns = useMemo(
    () => [
      {
        accessorKey: "id",
        header: "ID",
      },
      {
        accessorKey: "first_name",
        header: "First Name",
      },
      {
        accessorKey: "date_of_joining",
        header: "Date of joining",
      },
      {
        accessorKey: "email",
        header: "Email",
        Cell: ({ renderedCellValue, row }) => (
          <a href={`mailto:${renderedCellValue}`}>{renderedCellValue}</a>
        ),
      },
      {
        accessorKey: "phone_no",
        header: "Phone No.",
        Cell: ({ renderedCellValue }) => (
          <a href={`tel:${renderedCellValue}`}>{renderedCellValue}</a>
        ),
      },
      {
        accessorKey: "status",
        header: "Status",
        Cell: ({ cell }) => (
          <Box
            component="span"
            sx={(theme) => ({
              backgroundColor:
                cell.getValue() === "Deactive"
                  ? theme.palette.error.dark
                  : theme.palette.success.dark,
              borderRadius: "0.25rem",
              color: "#fff",
              maxWidth: "9ch",
            })}
          >
            {cell.getValue()}
          </Box>
        ),
      },
    ],
    []
  );

  return (
    <div>
      <div className="tableCard">
        <Table
          data={instructorList}
          columns={columns}
          initialState={{ columnVisibility: { id: false } }}
          enableColumnActions={false}
          enableColumnFilters={false}
          enableTopToolbar={false}
          enableRowActions
          positionActionsColumn="last"
          renderRowActions={({ cell, row, table }) => (
            <Box sx={{ display: "flex", flexWrap: "nowrap", gap: "8px" }}>
              <IconButton
                color="primary"
                onClick={() => {
                  navigate(`${row.original.id}/edit`);
                }}
              >
                <Edit />
              </IconButton>
              <IconButton
                color="primary"
                onClick={() => {
                  navigate(`${row.original.id}/detail`);
                }}
              >
                <Visibility />
              </IconButton>
              <IconButton
                color="secondary"
                onClick={() => {
                  deactivate(row.original.id, "delete");
                }}
              >
                <Delete />
              </IconButton>
              <IconButton
                color="error"
                onClick={() => {
                  deactivate(row.original.id, "deactivate", true);
                }}
              >
                <Block />
              </IconButton>
            </Box>
          )}
        />
      </div>
    </div>
  );
};
