import React, { useContext, useEffect, useState } from "react";
import { useParams } from "react-router";

import * as yup from "yup";
import moment from "moment";
import { isEmpty } from "lodash";

import { Input } from "../../../../components/ui/form/Input";
import { Form } from "../../../../components/ui/form/Form";
import { CommonContext } from "../../../../context/Common.context";
import { Date } from "../../../../components/ui/form/DatePicker";
import { Spinner } from "../../../../components/ui/Spinner";

const QualificationForm = ({ defaultValues, commonContextData }) => {
  const submitProfile = async (formData) => {
    const finalFormData = {
      ...formData,
      from_date: moment(formData.from_date).format("YYYY-MM-DD"),
      to_date: moment(formData.to_date).format("YYYY-MM-DD"),
    };
    await commonContextData.updateUserQualificationProfile(finalFormData);
  };

  const getDateFieldCommonValidation = (field) => {
    const errorMsg =
      field === "to_date"
        ? "To date should be greater"
        : "From date should be Smaller";
    const errorType = field === "to_date" ? "is-greater" : "is-smaller";
    return yup
      .date()
      .nullable(true)
      .test(errorType, errorMsg, function (value) {
        const validationField =
          field === "to_date" ? this.parent.from_date : this.parent.to_date;
        if (validationField !== null) {
          if (field === "to_date")
            return moment(value).isSameOrAfter(moment(validationField));
          else return moment(value).isSameOrBefore(moment(validationField));
        } else {
          return true;
        }
      });
  };

  const validationSchema = {
    to_date: getDateFieldCommonValidation("to_date"),
    from_date: getDateFieldCommonValidation("from_date"),
  };

  return (
    <Form
      onSubmit={(formData) => submitProfile(formData)}
      defaultValues={{ ...defaultValues }}
      submitButtonText="Submit"
      redirectUrl="/instructors"
      validationSchema={validationSchema}
    >
      <Input type="text" name="college" placeholder="College" fullWidth />
      <Input type="text" name="university" placeholder="University" fullWidth />
      <Input
        type="text"
        name="course_type"
        placeholder="Course type"
        fullWidth
      />
      <Input type="text" name="qualification" placeholder="Degree" fullWidth />
      {/* <Input type="text" name="from_date" placeholder="From date" fullWidth />
      <Input type="text" name="to_date" placeholder="To date" fullWidth /> */}
      <Date label="From date" name="from_date" />
      <Date label="To date" name="to_date" />
      <Input
        type="text"
        name="duration_of_course"
        placeholder="Duration of course"
        fullWidth
      />
    </Form>
  );
};

export const Qualification = (props) => {
  const { id } = useParams();
  const commonContextData = useContext(CommonContext);
  const { userQualification } = commonContextData.state;
  const [isLoaded, setLoaded] = useState(false);
  let initialValue = { ...userQualification, user_id: id };

  /** if Qualification profile already exist then insert qualification_id into payload */
  if (!isEmpty(userQualification)) {
    initialValue = {
      ...initialValue,
      qualification_id: userQualification.id,
    };
  }

  useEffect(() => {
    if (!isLoaded) {
      (async () => {
        await commonContextData.getUserQualificationProfile(id);
        setLoaded(true);
      })();
    }
  }, [isLoaded]);

  return isLoaded ? (
    <QualificationForm
      defaultValues={initialValue}
      commonContextData={commonContextData}
    />
  ) : (
    <Spinner />
  );
};
