import React from "react";
import { useNavigate } from "react-router";

import withLayout from "../../hoc/Layout";
import { ScheduleProvider } from "../../context/Scheduler.context";
import { ScheduleList } from "./Summary";
import { Button } from "../../components/ui/Button";

const Schedules = (props) => {
  const navigate = useNavigate();

  return (
    <div className="container-fluid">
      <div className="page-header">
        <h2>Flight Schedules</h2>
        {/* <Button variant="outlined" onClick={() => navigate("create")} size="small">
          Create Schedule
        </Button> */}
      </div>
      <div>
        <ScheduleProvider>
          <ScheduleList />
        </ScheduleProvider>
      </div>
    </div>
  );
};

export default withLayout(Schedules);
