import React, { useState } from "react";
import swal from "sweetalert";

import api from "../utils/axios";
import { useNavigate } from "react-router";

export const StudentContext = React.createContext(null);

const initialState = {
  studentList: [],
};

export const StudentProvider = (props) => {
  const navigate = useNavigate();
  const [state, setState] = useState(initialState);
  const setStudentData = (studentList) => {
    setState((prevState) => ({ ...prevState, studentList }));
  };

  /** Get all students */
  const getStudents = async () => {
    try {
      const getStudentData = await api.get(
        `accounts/account-user-invite/student-list/`
      );

      setStudentData(getStudentData?.data[0].data || []);
    } catch (error) {
      console.log("Error", error);
    }
  };

  /** Add user (student/instructor) */
  const addUser = async (data, redirectURL) => {
    try {
      await api.post(`accounts/account-user-invite/invite-student/`, data);
      swal("New Student Added Successfully", "", "success", {
        button: "ok",
      }).then(() => {
        navigate(redirectURL);
      });
    } catch (error) {
      swal("Error in Adding student", "", "danger", {
        button: "ok",
      });
    }
  };

  return (
    <StudentContext.Provider
      value={{
        state,
        getStudents,
        addUser,
      }}
    >
      {props.children}
    </StudentContext.Provider>
  );
};
