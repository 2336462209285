import React from "react";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { FormControl, FormHelperText } from "@mui/material";
import dayjs from "dayjs";

var flatten = require("flat");

export const Date = (props) => {
  const { errors, ...others } = props;
  const flatError = flatten(errors);

  return (
    <FormControl className="form--control date-picker">
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DatePicker
          {...others}
          format="YYYY-MM-DD"
          value={dayjs(props.value)}
        />
        {flatError[`${props.name}.message`] && (
          <FormHelperText error>
            {flatError[`${props.name}.message`]}
          </FormHelperText>
        )}
      </LocalizationProvider>
    </FormControl>
  );
};
